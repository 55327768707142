import {FieldGuesser} from "@api-platform/admin";
import {
    useTranslate,
    ReferenceArrayField,
    TextField,
    ReferenceField,
    SingleFieldList, ChipField, SimpleShowLayout, useShowController, Loading, useRecordContext,
} from "react-admin";
import {LocationTitle} from "./locationTitle";
import {Card} from "@mui/material";
import {ShowBase} from "ra-core";
import * as React from "react";

import {Title, EditButton, TopToolbar} from 'react-admin';
import {LocationContacts} from "./locationContacts";
import {LocationDevices} from "./locationDevices";
import {LocationChangesRecord} from "./locationChangesRecord";
import {ShowLayoutRoot} from "./locationShowStyles";

// @ts-ignore
const MyEditButton = props => {
    const record = useRecordContext();
    return <EditButton resource={'/locations'} record={record} {...props}/>
}
//  @ts-ignore
export const LocationShow = props => {
    const translate = useTranslate();


    const {
        isLoading
    } = useShowController();

    if (isLoading) {
        return <Loading/>;
    }

    return <>
        <ShowBase resource="locations">

            <ShowLayoutRoot className={'show-page'} sx={{marginTop: '1em'}}>
                <Title
                    title={<LocationTitle/>}
                />
                <TopToolbar>
                   <MyEditButton/>
                </TopToolbar>
                <Card>
                    <SimpleShowLayout>
                        <FieldGuesser source={"name"} label={translate('ra.location.name')}/>
                        <FieldGuesser source={"address"} label={translate('ra.location.address')}/>
                        <FieldGuesser source={"city"} label={translate('ra.location.city')}/>
                        <FieldGuesser source={"postCode"} label={translate('ra.location.postCode')}/>
                        <FieldGuesser source={"workingHours"} label={translate('ra.location.workingHours')}/>

                        <ReferenceField source={"client"} reference="clients" link={"show"}
                                        label={translate('ra.client.name')}>
                            <TextField source="name"/>
                        </ReferenceField>
                        <ReferenceArrayField source={"tags"} reference="location_tags"
                                             label={translate('ra.location_tag.name')}>
                            <SingleFieldList>
                                <ChipField source="name"/>
                            </SingleFieldList>
                        </ReferenceArrayField>
                    </SimpleShowLayout>
                </Card>

                <LocationContacts/>

                <LocationDevices/>

                <LocationChangesRecord/>


            </ShowLayoutRoot>


        </ShowBase>
    </>
};
