import {Dashboard} from './Dashboard';
import BusinessIcon from '@mui/icons-material/Business';
import PeopleIcon from '@mui/icons-material/People';
import CategoryIcon from '@mui/icons-material/Category';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import HandshakeIcon from '@mui/icons-material/Handshake';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import HardwareIcon from '@mui/icons-material/Hardware';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PaletteIcon from '@mui/icons-material/Palette';


import {dataProvider} from './providers/dataProvider';
import {queryClient} from "./providers/queryClient";
import {authProvider} from './providers/authProvider';
import {i18nProvider} from './providers/i18nProvider';
import {HydraAdmin, ResourceGuesser} from "@api-platform/admin";
import {ScentLayout} from "./layout/ScentLayout";
import {darkTheme, lightTheme} from './layout/theme';
import {ENTRYPOINT} from "./config/entrypoint";
import {PartnerList, PartnerShow, PartnerEdit, PartnerCreate} from "./partners";
import {ProductTypeList, ProductTypeCreate, ProductTypeEdit, ProductTypeShow} from "./productTypes";
import {ProductCreate, ProductEdit, ProductList, ProductShow} from "./products";
import {ClientCreate, ClientEdit, ClientList, ClientShow} from "./clients";
import {ClientContactCreate, ClientContactEdit, ClientContactList, ClientContactShow} from "./clientContacts";
import {LocationCreate, LocationEdit, LocationList, LocationShow} from "./locations";
import {UserCreate, UserEdit, UserList, UserShow} from "./users";
import {ChangeCreate} from "./changes/changeCreate";
import {ChangeList} from "./changes/changesList";
import {CustomRoutes} from "react-admin";
import {Route} from "react-router-dom";
import {DeviceProductCreate, DeviceProductEdit, DeviceProductList, DeviceProductShow} from "./deviceProducts";
import {DeviceColorList, DeviceColorCreate, DeviceColorEdit, DeviceColorShow} from "./deviceColors";
import {DeviceCreate} from "./devices";
import {LocationTagCreate, LocationTagEdit, LocationTagList, LocationTagShow} from "./locationTags";
import {ChangeListPrint} from "./changes/chaneListPrint";
import {LoadingOrderList} from "./loadingOrder/loadingOrderList";
import {LoadingOrderListPrint} from "./loadingOrder/loadingOrderListPrint";
import {ReportMain} from "./report/reportMain";
import {Fragment} from "react";

import {  houseLightTheme, houseDarkTheme } from 'react-admin';

const App = () => {
    return (
        <>
            <HydraAdmin queryClient={queryClient} layout={ScentLayout} dataProvider={dataProvider}
                        authProvider={authProvider}
                        i18nProvider={i18nProvider}
                        entrypoint={ENTRYPOINT} dashboard={Dashboard}
                        theme={houseLightTheme} darkTheme={houseDarkTheme} disableTelemetry>
                {permissions => (
                    <>
                        {permissions === 'admin'
                            ?
                            <ResourceGuesser name={"partners"} list={PartnerList} show={PartnerShow} edit={PartnerEdit}
                                             create={PartnerCreate} icon={HandshakeIcon} options={{label: 'Partneri'}}/>
                            : null}
                        {permissions === 'admin'
                            ? <ResourceGuesser name={"users"} list={UserList} edit={UserEdit} create={UserCreate}
                                               show={UserShow} icon={PeopleIcon} options={{label: 'Korisnici'}}/>
                            : null}
                        {permissions === 'admin'
                            ? <ResourceGuesser name={"product_types"} list={ProductTypeList} show={ProductTypeShow}
                                               edit={ProductTypeEdit} create={ProductTypeCreate} icon={CategoryIcon}
                                               options={{label: 'Kategorije proizvoda'}}/>
                            : null}
                        {permissions === 'admin'
                            ?
                            <ResourceGuesser name={"products"} list={ProductList} show={ProductShow} edit={ProductEdit}
                                             create={ProductCreate} icon={ShoppingBasketIcon}
                                             options={{label: 'Proizvodi'}}/>
                            : null}
                        {permissions === 'admin'
                            ?
                            <ResourceGuesser name={"device_products"} list={DeviceProductList}
                                             show={DeviceProductShow} edit={DeviceProductEdit}
                                             create={DeviceProductCreate} icon={HardwareIcon}
                                             options={{label: 'Uređaji'}}/>

                            : null}
                        {permissions === 'admin'
                            ?

                            <ResourceGuesser name={"device_colors"} list={DeviceColorList}
                                             create={DeviceColorCreate} edit={DeviceColorEdit}
                                                show={DeviceColorShow} icon={PaletteIcon}
                                             options={{label: 'Boje'}}/>
                            : null}
                        <CustomRoutes>
                            <Route path="/reports" element={<ReportMain print={false}/>}/>

                        </CustomRoutes>
                        <CustomRoutes noLayout>

                            <Route path="/reports/print" element={<ReportMain print={true}/>}/>
                        </CustomRoutes>
                        {permissions !== 'admin'
                            ?
                            <Fragment>
                                <ResourceGuesser name={"clients"} list={ClientList} show={ClientShow} edit={ClientEdit}
                                                 create={ClientCreate} icon={StorefrontIcon}
                                                 options={{label: 'Klijenti'}}/>
                                <ResourceGuesser name={"client_contacts"} list={ClientContactList}
                                                 show={ClientContactShow}
                                                 edit={ClientContactEdit} create={ClientContactCreate}
                                                 icon={ContactPhoneIcon}
                                                 options={{label: 'Kontakti'}}/>
                                <ResourceGuesser name={"locations"} list={LocationList} edit={LocationEdit}
                                                 create={LocationCreate}
                                                 show={LocationShow} icon={BusinessIcon}
                                                 options={{label: 'Lokacije'}}/>
                                <ResourceGuesser name={"changes"} create={ChangeCreate}
                                                 options={{label: 'Zamene'}}/>
                                <ResourceGuesser name={"devices"} create={DeviceCreate}
                                                 options={{label: 'Dodeljeni Uređaji'}}/>
                                <ResourceGuesser name={"location_tags"} list={LocationTagList} show={LocationTagShow}
                                                 edit={LocationTagEdit}
                                                 create={LocationTagCreate} icon={LocalOfferIcon}
                                                 options={{label: 'Tagovi'}}/>
                                <CustomRoutes>
                                    <Route path="/changes/work" element={<ChangeList/>}/>
                                    <Route path="/changes/loading" element={<LoadingOrderList/>}/>
                                </CustomRoutes>
                                <CustomRoutes noLayout>
                                    <Route path="/changes/print" element={<ChangeListPrint/>}/>
                                    <Route path="/changes/loading/print" element={<LoadingOrderListPrint/>}/>

                                </CustomRoutes>
                            </Fragment>
                            : null}


                    </>

                )}
            </HydraAdmin>
        </>
    );
}
export default App;