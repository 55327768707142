import {FieldGuesser} from "@api-platform/admin";
import {ShowGuesser} from "@api-platform/admin";
import {useTranslate, ReferenceArrayField, Datagrid, TextField, ShowButton, ReferenceField,} from "react-admin";
import {ClientContactTitle} from "./clientContactTitle";

//  @ts-ignore
export const ClientContactShow = props => {
    const translate = useTranslate();
    return <>
        <ShowGuesser {...props} title={<ClientContactTitle/>}>

            <FieldGuesser source={"name"} label={translate('ra.client_contact.name')}/>
            <FieldGuesser source={"title"} label={translate('ra.client_contact.title')}/>
            <FieldGuesser source={"phone"} label={translate('ra.client_contact.phone')}/>
            <FieldGuesser source={"email"} label={translate('ra.client_contact.email')}/>
            <ReferenceField source={"client"} reference="clients" link={"show"}
                            label={translate('ra.client.name')}>
                <TextField source="name"/>
            </ReferenceField>
            <h3>Lokacije</h3>
            <ReferenceArrayField source={"locations"} reference={"locations"} label={false}>
                <Datagrid bulkActionButtons={false}>
                    <TextField source="name" label={translate('ra.location.name')}/>
                    <TextField source="address" label={translate('ra.location.address')}/>
                    <TextField source="city" label={translate('ra.location.city')}/>
                    <TextField source="workingHours" label={translate('ra.location.workingHours')}/>
                    <ShowButton/>
                </Datagrid>
            </ReferenceArrayField>

        </ShowGuesser>
    </>
};