import {Confirm, useDataProvider, useNotify, useRecordContext, useRefresh, useTranslate} from "react-admin";
import DeleteIcon from "@mui/icons-material/Delete";
import * as React from "react";
import {StyledButton} from "../components/styledButton";


// @ts-ignore
export const DeleteChange = (props) => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const translate = useTranslate()
    const notify = useNotify();
    const refresh = useRefresh();

    const [open, setOpen] = React.useState(false);

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleDelete = () => {
        dataProvider.bulkSubmitChanges([{
            id: parseInt(record?.originId),
            status: '/statuses/4',
            numOfItems: record?.numOfItems,
            duration: record?.duration,
            product: record?.product,
        }])
            // @ts-ignore
            .then(response => {
                notify(`ra.changes.remove_change_success`, {type: 'success'});
                // @ts-ignore
                refresh();
            })
            // @ts-ignore
            .catch(error => {
                notify(`ra.changes.error_500`, {type: 'error'});
            });
        setOpen(false);
    }
    const isLoading = false;

    // @ts-ignore
    return <>
        <StyledButton
            onClick={handleClick}
            label={translate('ra.action.delete')}
            key="button">
            <DeleteIcon/>
        </StyledButton>
        <Confirm
            isOpen={open}
            loading={isLoading}
            title={translate('ra.message.delete_title')}
            content={translate('ra.message.delete_content')}

            onConfirm={handleDelete}
            onClose={handleDialogClose}
        />

    </>

}