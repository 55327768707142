import {FieldGuesser, ListGuesser} from "@api-platform/admin";
import {useTranslate, Filter, TextInput} from 'react-admin';

//  @ts-ignore
const FilterBar= (props) => {
    const translate = useTranslate();
    return <Filter context={"button"} {...props}>
        <TextInput label={translate('ra.device_product.name')} source='name'/>

    </Filter>
}

// @ts-ignore
export const DeviceProductList = props => {
    const translate = useTranslate();

    return <>
        <ListGuesser {...props} bulkActionButtons={false} {...props} exporter={false} filters={<FilterBar/>} title={"Lista uređaja"}>
            <FieldGuesser source={"name"} label={translate('ra.device_product.name')}/>

        </ListGuesser>
    </>
};