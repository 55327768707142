import {CreateGuesser} from "@api-platform/admin";
import {DeviceColorInputFields} from "./deviceColorInputFields";
import {useTranslate} from "react-admin";
// @ts-ignore
export const DeviceColorCreate = props => {
        const translate = useTranslate();

        return <CreateGuesser {...props} title={translate('ra.device_colors.new_item')}>
            <DeviceColorInputFields {...props}/>
        </CreateGuesser>
    }
;