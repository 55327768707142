import {InputGuesser} from "@api-platform/admin";
import {useTranslate} from "react-admin";

// @ts-ignore
export const ProductTypeInputFields = (props) => {
    const translate = useTranslate();
    // @ts-ignore
    return <>
        <InputGuesser source={"name"} label={translate('ra.product_type.name')}/>
    </>
};