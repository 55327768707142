import clsx from "clsx";
import {SaveButton,} from 'react-admin';
import {styled} from "@mui/material/styles";
import {Theme, Toolbar as MuiToolbar, useMediaQuery} from "@mui/material";

const PREFIX = 'RaToolbar';

export const ToolbarClasses = {
    desktopToolbar: `${PREFIX}-desktopToolbar`,
    mobileToolbar: `${PREFIX}-mobileToolbar`,
    defaultToolbar: `${PREFIX}-defaultToolbar`,
};

const StyledToolbar = styled(MuiToolbar, {
    name: PREFIX,
    overridesResolver: (props, styles) => styles.root,
})(({theme}) => ({
    backgroundColor:
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],

    [`&.${ToolbarClasses.desktopToolbar}`]: {},

    [`&.${ToolbarClasses.mobileToolbar}`]: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        padding: '16px',
        width: '100%',
        boxSizing: 'border-box',
        flexShrink: 0,
        zIndex: 2,
    },

    [`& .${ToolbarClasses.defaultToolbar}`]: {
        flex: 1,
        display: 'flex',
        justifyContent: 'space-between',
    },
}));
// @ts-ignore
export const ScentToolbar = (props) =>
{
    const isXs = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'));
    return <StyledToolbar
        className={clsx(
            {
                [ToolbarClasses.mobileToolbar]: isXs,
                [ToolbarClasses.desktopToolbar]: !isXs,
            }
        )}
        role="toolbar"
    >
        <div className={ToolbarClasses.defaultToolbar}>
            <SaveButton/>
            {props.deleteButton}
        </div>
    </StyledToolbar>
};