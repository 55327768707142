import {
    Button,
    Card,
    CardContent, InputLabel, MenuItem,
    TextField,

} from "@mui/material";
import * as React from "react";
import {useTranslate, useRedirect, usePermissions} from "react-admin";
import {useForm} from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

// @ts-ignore

// @ts-ignore
export const ReportFilters = (props) => {

    const translate = useTranslate();
    const {
        register,
        handleSubmit,
    } = useForm();
    const redirect = useRedirect();
    // @ts-ignore
    const onSubmit = (data) => {
        redirect('/reports?startDate=' + data.startDate + '&endDate=' + data.endDate + '&report_type=' + data.report_type);
    }
    const [report_type, setReportType] = React.useState(props.report_type);
    // @ts-ignore
    const handleChange = (event) => {
        setReportType(event.target.value);
    }

    const {permissions} = usePermissions();
    return <Card sx={{marginTop: 4}}>
        <CardContent className={"change-top-filters"}>
            <form onSubmit={handleSubmit(onSubmit)}>

                <TextField {...register('startDate')} label={translate('ra.reports.start_date')} type="date"
                           defaultValue={props.startDateFormatted} sx={{minWidth: 150, maxWidth: 150}} />

                <TextField {...register('endDate')} label={translate('ra.reports.end_date')} type="date"
                           defaultValue={props.endDateFormatted} sx={{marginLeft: 2, minWidth: 150, maxWidth: 150}} />

                <FormControl sx={{marginLeft: 2, minWidth: 150, maxWidth: 150}} variant="filled">
                    <InputLabel id="report_type">{translate('ra.reports.type')}</InputLabel>
                    <Select labelId="report_type" label={translate('ra.reports.type')}  {...register('report_type')}
                            value={report_type} onChange={handleChange}>
                        <MenuItem value={''}>--</MenuItem>
                        <MenuItem value={'changes'}>{translate('ra.reports.changes')}</MenuItem>
                        <MenuItem value={'clients'}>{translate('ra.reports.clients')}</MenuItem>
                        <MenuItem value={'devices'}>{translate('ra.reports.devices')}</MenuItem>
                        <MenuItem value={'products'}>{translate('ra.reports.product')}</MenuItem>
                        <MenuItem value={'product_types'}>{translate('ra.reports.product_types')}</MenuItem>
                        {permissions === 'admin' ?
                            <MenuItem value={'partners'}>{translate('ra.reports.partners')}</MenuItem>
                            : null}

                    </Select>
                </FormControl>


                <Button type="submit" variant="contained" sx={{
                    marginTop: 1,
                    marginLeft: 2,
                    lineHeight: 2.5
                }}>{translate('ra.reports.filter_change')}</Button>
            </form>


        </CardContent>
    </Card>
}