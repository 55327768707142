import {InputGuesser} from "@api-platform/admin";
import {TextInput, useTranslate} from "react-admin";

// @ts-ignore
export const ClientFields = (props) => {
    const translate = useTranslate();
    // @ts-ignore
    return <>
        <InputGuesser source={"name"} label={translate('ra.client.name')}/>
        <TextInput source={"address"} label={translate('ra.client.address')}/>
        <InputGuesser source={"city"} label={translate('ra.company.city')}/>
        <InputGuesser source={"postCode"} label={translate('ra.client.postCode')}/>
        <InputGuesser source={"pib"} label={translate('ra.client.pib')}/>
        <InputGuesser source={"registrationNumber"} label={translate('ra.client.registrationNumber')}/>
        <InputGuesser source={"phone"} label={translate('ra.client.phone')}/>
        <InputGuesser source={"email"} label={translate('ra.client.email')} type="email"/>
        <InputGuesser source={"site"} label={translate('ra.client.site')} type="text" defaultValue={'http://'}/>
        <InputGuesser source={"workingHours"} label={translate('ra.client.workHours')}/>
    </>
};