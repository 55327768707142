import {DeviceProductInput} from "./deviceProductInput";
import {Edit, SimpleForm} from "react-admin";
import {ScentToolbar} from "../layout/ScentToolbar";
import {DeleteDeviceProduct} from "./deleteDeviceProduct";
import {DeviceProductTitle} from "./deviceProductTitle";



// @ts-ignore
export const DeviceProductEdit = (props) => {
    const deleteButton = <DeleteDeviceProduct/>;
    return <>
        <Edit title={<DeviceProductTitle/>}>
            <SimpleForm toolbar={<ScentToolbar deleteButton={deleteButton}/>}>
                <DeviceProductInput {...props}/>
            </SimpleForm>
        </Edit>

    </>
};