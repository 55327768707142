import {styled} from "@mui/material/styles";

const PREFIX = 'RaSimpleShowLayout';
const SimpleShowLayoutClasses = {
    stack: `${PREFIX}-stack`,
    row: `${PREFIX}-row`,
};

export const ShowLayoutRoot = styled('div', {
    name: PREFIX,
    overridesResolver: (props, styles) => styles.root,
})(({theme}) => ({
    flex: 1,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    [`& .${SimpleShowLayoutClasses.stack}`]: {},
    [`& .${SimpleShowLayoutClasses.row}`]: {
        display: 'inline',
    },
}));