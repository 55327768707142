import {FieldGuesser, ListGuesser} from "@api-platform/admin";
import {useTranslate, Filter, TextInput} from 'react-admin';

// @ts-ignore
const FilterBar= (props) => {
    const translate = useTranslate();
    return <Filter context={"button"} {...props}>
        <TextInput label={translate('ra.company.name')} source='name'/>
        <TextInput label={translate('ra.company.city')} source='city'/>
    </Filter>
}

// @ts-ignore
export const PartnerList = (props) => {
    const translate = useTranslate();
    return <>
        <ListGuesser bulkActionButtons={false} {...props} exporter={false} filters={<FilterBar/>} title={'Partneri'}>
            <FieldGuesser source={"name"} label={translate('ra.company.name')}/>
            <FieldGuesser source={"city"} label={translate('ra.company.city')}/>
            <FieldGuesser source={"phone"} label={translate('ra.company.phone')}/>
            <FieldGuesser source={"email"}/>
            <FieldGuesser source={"workHours"} label={translate('ra.company.workHours')}/>
        </ListGuesser>
    </>
};