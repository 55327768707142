import {CreateGuesser} from "@api-platform/admin";

import {useSearchParams} from "react-router-dom";
import {
    AutocompleteInput,
    BooleanInput,
    DateInput,
    Loading,
    ReferenceInput, TextInput,
    useGetOne,
    useTranslate
} from "react-admin";
import {padDate} from "../utils/dateUtil";
// @ts-ignore
export const DeviceCreate = props => {
        const translate = useTranslate();
        const [searchParams] = useSearchParams();
        let defaultLocation = searchParams.get('location');
        const redirectLocation = "/locations/" + encodeURIComponent(defaultLocation !== null ? defaultLocation : "") + "/show"

        const {data, isLoading, error} = useGetOne('locations', {id: defaultLocation});
        if (isLoading || error) {
            return <Loading/>;
        }

        let startDate = new Date();
        const startDateFormatted = startDate.getFullYear() + "-" + padDate(startDate.getMonth() + 1) + "-" + padDate(startDate.getDate());

        return <CreateGuesser {...props} title={"Dodaj uređaj"} redirect={redirectLocation}>
            <ReferenceInput source={"location"} reference="locations">
                <AutocompleteInput optionText={"name"} label={translate('ra.location.name')} defaultValue={data.id} disabled
                                   sx={{width: 300}}/>
            </ReferenceInput>
            <ReferenceInput source={"deviceProduct"} reference="device_products">
                <AutocompleteInput optionText="name" label={translate('ra.device_product.name')} sx={{width: 300}}/>
            </ReferenceInput>
            <ReferenceInput source={"color"} reference="device_colors">
                <AutocompleteInput optionText="name" label={translate('ra.device_colors.name')} sx={{width: 300}}/>
            </ReferenceInput>
            <BooleanInput label={translate('ra.device.loaned')} source="loaned" />
            <TextInput label={translate('ra.device.note')} source="note" multiline={true} sx={{width: 300}}/>

            <DateInput source={"startAt"} label={translate('ra.device.montage_date')} type={"date"}
                       defaultValue={startDateFormatted}/>
        </CreateGuesser>

    }
;