import * as React from "react";
import {Card, CardContent, Grid} from '@mui/material';
import {Title, useTranslate, useDataProvider, useNotify, Loading} from 'react-admin';

import {useSearchParams} from 'react-router-dom';
import {padDate} from "../utils/dateUtil";
import {useEffect} from "react";
import {LoadingOrderFilter} from "./loadingOrderFilter";
import LoadingButton from "@mui/lab/LoadingButton";
import {Print} from "@mui/icons-material";
import EventNoteIcon from "@mui/icons-material/EventNote";


// @ts-ignore
export const LoadingOrderList = () => {
        const translate = useTranslate();
        const dataProvider = useDataProvider();
        const notify = useNotify();

        const [searchParams] = useSearchParams();
        const [orders, setOrders] = React.useState([]);
        const [loading, setLoading] = React.useState(false);


        let date = searchParams.get('date');
        let startDate = date !== null ? new Date(date) : new Date();
        const startDateFormatted = startDate.getFullYear() + "-" + padDate(startDate.getMonth() + 1) + "-" + padDate(startDate.getDate());

        useEffect(() => {
            setLoading(true);
            dataProvider.getLoadingOrders(startDateFormatted)
                // @ts-ignore
                .then(response => {
                    setOrders(response.json['hydra:member'])
                    setLoading(false);
                })
                // @ts-ignore
                .catch(error => {
                    notify(`ra.notification.http_error`, {type: 'error'});
                    setLoading(false);
                });
        }, [date, dataProvider, startDateFormatted, notify]);

        if (loading) {
            return <Loading/>
        }

        return <>
            <LoadingOrderFilter startDateFormatted={startDateFormatted}/>
            <Title title={translate('ra.loading_order.label')}/>
            <Card sx={{marginTop: 1}}>
                <CardContent>
                    <Grid container columns={12}>
                        <Grid item xs={6} className={"change-row-header"}>
                            {translate('ra.product.name')}
                        </Grid>
                        <Grid item xs={6} className={"change-row-header"}>
                            {translate('ra.changes.numberOfItems')}
                        </Grid>
                    </Grid>

                    {orders.map((order) => {
                        // @ts-ignore
                        return <>
                            <Grid container columns={12}>
                                <Grid item xs={6} className={"change-row-border"}>
                                    {// @ts-ignore
                                        order.product.name}
                                </Grid>
                                <Grid item xs={6} className={"change-row-border"}>
                                    {// @ts-ignore
                                        order.numOfItems}
                                </Grid>
                            </Grid>

                        </>
                    })}

                    {orders.length === 0 &&
                        <div>{translate('ra.loading_order.no_orders')}</div>
                    }
                    <Grid container sx={{marginTop: 2}}>
                        <LoadingButton startIcon={<Print/>} variant="contained" color={"info"}
                                       href={"#/changes/loading/print?date=" + startDateFormatted}
                                       target={"_blank"}
                                       sx={{marginRight: 1}}
                        >{translate('ra.loading_order.print.print_order')}</LoadingButton>


                        <LoadingButton startIcon={<EventNoteIcon/>} variant="contained"
                                       href={"#/changes/work?date=" + startDateFormatted}
                        >{translate('ra.changes.label')}</LoadingButton>
                    </Grid>


                </CardContent>
            </Card>
        </>
    }
;
