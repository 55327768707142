// @ts-ignore
import {
    Datagrid,
    ListContextProvider,
    ReferenceField,
    useListController,
    useTranslate,
    TextField,
    FunctionField,
} from "react-admin";

import * as React from "react";
import {padDate} from "../utils/dateUtil";
import {DeleteChange} from "./deleteChange";


// @ts-ignore
export const LocationChanges = (props) => {
        const translate = useTranslate();


        const changes = useListController({
            resource: 'changes',
            perPage: 1000,
            filter: {
                'location': props.location['@id'],
                'status': '/statuses/1',
            }

        });
        // @ts-ignore
        const renderDate = (record) => {

            let endAtDate = new Date(record.endAt);
            endAtDate.setDate(endAtDate.getDate() + parseInt(record.duration));
            return padDate(endAtDate.getDate()) + ". " + (endAtDate.toLocaleString('sr-Latn-RS', {month: 'short'})) + ". " + endAtDate.getFullYear();

        }

        return <>


            <ListContextProvider value={changes}>
                <Datagrid bulkActionButtons={false}>
                    <TextField source="product.name" label={translate('ra.product.name')} fullWidth sortable={false}/>
                    <ReferenceField source="product.productType" reference="product_types" link={false}>
                        <TextField source="name" sortable={false}/>
                    </ReferenceField>
                    <TextField source="numOfItems" label={translate('ra.changes.numberOfItems')} sortable={false}/>
                    <TextField source="duration" label={translate('ra.changes.duration')} sortable={false}/>
                    <FunctionField render={renderDate} label={translate('ra.changes.nextChange')} sortable={false}/>
                    <TextField source="note" label={translate('ra.changes.note')} sortable={false}/>
                    <div style={{textAlign: "right"}}>
                        <DeleteChange/>
                    </div>
                </Datagrid>
            </ListContextProvider>

        </>
    }
;