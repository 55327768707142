import {FieldGuesser} from "@api-platform/admin";
import {ShowGuesser} from "@api-platform/admin";
import {useTranslate} from "react-admin";
import {ProductTypeTitle} from "./productTypeTitle";


// @ts-ignore
export const ProductTypeShow = props => {
    const translate = useTranslate();
    return <>
        <ShowGuesser {...props} title={<ProductTypeTitle/>}>
            <FieldGuesser source={"name"} label={translate('ra.product_type.name')}/>
        </ShowGuesser>
    </>
};