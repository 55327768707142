import {
    Alert,
    AlertTitle,
    Button,
    Card,
    CardContent,
    Link,
    MenuItem,
    InputLabel,
    IconButton, Box
} from "@mui/material";

import Select, {SelectChangeEvent} from '@mui/material/Select';
import {padDate, renderDate} from "../utils/dateUtil";
import * as React from "react";
import {useDataProvider, useTranslate, useRedirect, useNotify} from "react-admin";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import FormControl from '@mui/material/FormControl';
import {ColumnChooser} from "./columnChooser";
import SettingsIcon from '@mui/icons-material/Settings';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFnsV3'
import {RS} from '../translation/calendarRS'
import {srLatn} from 'date-fns/locale';
// @ts-ignore
const AlertList = (props) => {
    const notify = useNotify();

    const [otherDates, setOtherDates] = React.useState<any[]>([]);
    const dataProvider = useDataProvider();

    const formatDateLink = (inputDate: string) => {
        const date = new Date(inputDate);

        return date.getFullYear() + "-" + padDate(date.getMonth() + 1) + "-" + padDate(date.getDate());
    }

    useEffect(() => {
        // @ts-ignore
        dataProvider.getOtherDates().then(response => {
            setOtherDates(response.json['hydra:member'])
        })
            // @ts-ignore
            .catch(error => {
                notify(`ra.changes.error_500`, {type: 'error'});
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.changes]);
    let i = 0;
    if (otherDates.length === 0) {
        return <></>
    }

    return <Alert severity="info">
        <AlertTitle>Radni nalozi u prethodnim danima</AlertTitle>
        Postoje radni nalozi za datume:&nbsp;
        {otherDates.map((date) => {
            if (i++ > 0) {
                return <strong key={date.date}>, <Link
                    component="button"
                    onClick={() => {
                        props.setValue('date', formatDateLink(date.date));
                        props.onSubmit({date: formatDateLink(date.date)})
                    }}>{renderDate(date.date)}</Link></strong>

            }
            return <strong key={date.date}><Link
                component="button"
                onClick={() => {
                    props.setValue('date', formatDateLink(date.date));
                    props.onSubmit({date: formatDateLink(date.date)})
                }}>{renderDate(date.date)}</Link></strong>
        })
        }

    </Alert>
}
// @ts-ignore
export const ChangeListFilter = (props) => {

    const translate = useTranslate();
    const {
        register,
        handleSubmit,
        setValue

    } = useForm();
    const redirect = useRedirect();
    // @ts-ignore
    const onSubmit = (data) => {
        redirect('/changes/work?date=' + start_date + '&sort_field=' + sort_field + '&sort_direction=' + sort_direction);
    }
    const [sort_field, setSortField] = React.useState(props.sort_field);
    const [sort_direction, setSortDirection] = React.useState(props.sort_direction);
    const [start_date, setStartDate] = React.useState(props.start_date);

    const handleSortChange = (event: SelectChangeEvent) => {
        setSortField(event.target.value as string);
    };
    const handleDirectionChange = (event: SelectChangeEvent) => {
        setSortDirection(event.target.value as string);
    };
    const handleDateChange = (value: Date | null) => {
        // @ts-ignore
        setStartDate( `${value?.getFullYear()}-${padDate(value?.getMonth() + 1)}-${padDate(value?.getDate())}`);

    };

    const dialogProps = {
        open: props.open,
        setOpen: props.setOpen,
        uiColumns: props.uiColumns,
        setUiColumns: props.setUiColumns,
    };
    const [open, setOpen] = useState(false);


    return <Card sx={{marginTop: 4}}>
        <CardContent className={"change-top-filters"}>
            <AlertList changes={props.changes} setValue={setValue} onSubmit={onSubmit}/>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box display="flex" sx={{flexDirection: 'row'}}>
                    <Box sx={{flexGrow: 1}}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={srLatn}
                                              localeText={RS.components.MuiLocalizationProvider.defaultProps.localeText}>
                            <DatePicker
                                open={open}
                                onOpen={() => setOpen(true)}
                                onClose={() => setOpen(false)}
                                slotProps={{
                                    textField: {
                                        onClick: () => setOpen(true),
                                    },
                                }}
                                onChange={handleDateChange}
                                label={translate('ra.changes.filter_date')}
                                defaultValue={new Date(props.startDateFormatted)}
                                sx={{maxWidth: 180}}

                            />
                        </LocalizationProvider>


                        <FormControl sx={{marginLeft: 2, minWidth: 150, maxWidth: 150}}>
                            <InputLabel id="sort_field">Sortiraj po</InputLabel>
                            <Select labelId="sort_field" label="Sortiraj po"  {...register('sort_field')}
                                    value={sort_field}
                                    onChange={handleSortChange}>
                                <MenuItem value={''}>--</MenuItem>
                                <MenuItem value={'location.tags.name'}>Tag</MenuItem>
                                <MenuItem value={'orderNum'}>Redosledu</MenuItem>
                                <MenuItem value={'client.name'}>Klijentu</MenuItem>
                                <MenuItem value={'location.city'}>Gradu</MenuItem>
                                <MenuItem value={'location.address'}>Adresi</MenuItem>
                                <MenuItem value={'location.postCode'}>Poštanskom broju</MenuItem>
                                <MenuItem value={'status.id'}>Statusu</MenuItem>
                                <MenuItem value={'product.name'}>Proizvodu</MenuItem>

                            </Select>
                        </FormControl>
                        <FormControl sx={{marginLeft: 2, minWidth: 150, maxWidth: 150}}>
                            <InputLabel id="order-sort">Redosled</InputLabel>
                            <Select labelId="order-sort" label="Redosled"  {...register('sort_direction')}
                                    value={sort_direction} onChange={handleDirectionChange}>
                                <MenuItem value={''}>--</MenuItem>
                                <MenuItem value={'asc'}>Rastuće</MenuItem>
                                <MenuItem value={'desc'}>Opadajuće</MenuItem>

                            </Select>
                        </FormControl>

                        <Button type="submit" variant="contained" sx={{
                            marginTop: 1,
                            marginLeft: 2,
                            lineHeight: 2.5
                        }}>{translate('ra.changes.filter_change')}</Button>
                    </Box>
                    <Box sx={{justifyContent: "flex-end"}}>
                        <IconButton aria-label="settings" size="large" sx={{fontSize: "large"}} onClick={() => {
                            props.setOpen(true)
                        }}>
                            <SettingsIcon/>
                        </IconButton>
                    </Box>
                </Box>
            </form>

            <ColumnChooser {...dialogProps}/>


        </CardContent>
    </Card>
}
