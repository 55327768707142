import {InputGuesser} from "@api-platform/admin";
import {
    ReferenceInput,

    SelectInput,
    useTranslate, useGetList, useRecordContext, ReferenceField, AutocompleteInput, ReferenceArrayInput, SelectArrayInput
} from "react-admin";
import {useEffect, useState} from "react";
import {Autocomplete, Chip, TextField} from "@mui/material";
import {useController} from "react-hook-form";


// @ts-ignore
const ClientContactList = (props) => {
        const client = props.client === '/clients/none' ? '' : props.client;
        const translate = useTranslate();
        // @ts-ignore
        const onAutocompleteChange = (event, newValue) => {
            let id: any[] = [];
            // @ts-ignore
            newValue.forEach((item) => {
                id.push(item.id);
            });
            // @ts-ignore
            props.clientContactChange(id);
            // @ts-ignore
            props.setContactValue([
                ...newValue,
            ]);
        };
        // @ts-ignore

        const {data, total, isLoading, error} = useGetList(
            'client_contacts',
            {
                pagination: {page: 1, perPage: 30},
                sort: {field: 'id', order: 'DESC'},
                filter: {client: `${client}`},
            }
        );

        if (isLoading || error) {
            return <></>;
        }

        // @ts-ignore
        var choices: any[] = [];
        var index = {};
        // @ts-ignore
        data.forEach(function (item) {
            // @ts-ignore
            index[item.id] = item;
            choices.push({id: item.id, name: item.name + ' - ' + item.title});
        });

        var selectData: any[] | undefined = [];
        props.contactValue.forEach((item: { id: string | number; }) => {
            // @ts-ignore
            selectData.push(index[item.id]);
        });


        // @ts-ignore
        return (
            <>
                <Autocomplete
                    multiple
                    value={selectData}
                    onChange={onAutocompleteChange}
                    options={choices}
                    getOptionLabel={(option) => option.name}
                    renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                            <Chip
                                label={option.name}
                                {...getTagProps({index})
                                }
                            />
                        ))
                    }
                    style={{width: 300}}
                    // @ts-ignore
                    renderInput={(params) => (
                        <TextField {...params} label={translate('ra.client_contact.name')}/>
                    )}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                />
            </>
        );
    }
;

const useGetFormChangeFunction = (name: string) => {
    // @ts-ignore
    const {field} = useController({name: name});

    return field.onChange;
}

// @ts-ignore
export const LocationFields = (props) => {
    const translate = useTranslate();
    const [client, setClient] = useState('/clients/none');
    const [contactValue, setContactValue] = useState([]);
    const clientContactChange = useGetFormChangeFunction('clientContact');
    const record = useRecordContext();
    useEffect(() => {
        if (record === undefined) {
            return;
        }
        setClient(record.client);
        let contactIds: any[] = [];
        // @ts-ignore
        record.clientContact.forEach((item) => {
            contactIds.push({id: item});
        });
        // @ts-ignore
        setContactValue(contactIds);
    }, []);

    // @ts-ignore
    const onChange = (record) => {
        setContactValue([]);
        clientContactChange([]);
        if (record.target.value === '') {
            setClient('/clients/none');
            return;
        }
        setClient(record.target.value);
    };


    // @ts-ignore
    return <>
        <InputGuesser source={"name"} label={translate('ra.location.name')}/>
        <InputGuesser source={"address"} label={translate('ra.location.address')}/>
        <InputGuesser source={"city"} label={translate('ra.location.city')}/>
        <InputGuesser source={"postCode"} label={translate('ra.location.postCode')}/>
        <InputGuesser source={"workingHours"} label={translate('ra.location.workingHours')}/>


        <ReferenceInput source={"client"} reference="clients">
            <SelectInput optionText="name" label={translate('ra.client.name')}  // @ts-ignore
                         onChange={onChange} disabled={props.create !== true}/>
        </ReferenceInput>

        <ClientContactList client={client} contactValue={contactValue} setContactValue={setContactValue}
                           clientContactChange={clientContactChange}/>

        <ReferenceArrayInput  source="tags" reference="location_tags">

            <AutocompleteInput multiple optionText="name" label={translate('ra.location_tag.name')} sx={{width: 300}}/>
        </ReferenceArrayInput>

    </>
};