import polyglotI18nProvider from 'ra-i18n-polyglot';
import en from 'ra-language-english';
// @ts-ignore
import serboLatinMessages from 'ra-language-serbo-latin'
import { rsMessages } from '../translation/rs';



// @ts-ignore
function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

// @ts-ignore
function mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return mergeDeep(target, ...sources);
}

const translations = { en, rs: mergeDeep(en, serboLatinMessages, rsMessages) };


export const i18nProvider = polyglotI18nProvider(
    // @ts-ignore
    (locale:string) => (translations[locale]),
    'rs',
);
