import { getPickersLocalization } from '@mui/x-date-pickers/locales/utils/getPickersLocalization';

// This object is not Partial<PickersLocaleText> because it is the default values

const rsPickers = {
  // Calendar navigation
  previousMonth: 'Prethodni mesec',
  nextMonth: 'Sledeći mesec',
  // View navigation
  openPreviousView: 'Otvori prethodni prikaz',
  openNextView: 'Otvori sledeći prikaz',
  calendarViewSwitchingButtonAriaLabel: view => view === 'year' ? 'year view is open, switch to calendar view' : 'calendar view is open, switch to year view',
  // DateRange labels
  start: 'Početak',
  end: 'Kraj',
  startDate: 'Početni datum',
  startTime: 'Početno vreme',
  endDate: 'Završni datum',
  endTime: 'Završno vreme',
  // Action bar
  cancelButtonLabel: 'Otkaži',
  clearButtonLabel: 'Obriši',
  okButtonLabel: 'OK',
  todayButtonLabel: 'Danas',
  // Toolbar titles
  datePickerToolbarTitle: 'Izaberi datum',
  dateTimePickerToolbarTitle: 'Izaberi datum i vreme',
  timePickerToolbarTitle: 'Izaberi vrem',
  dateRangePickerToolbarTitle: 'Izaberi kalendar period',
  // Clock labels
  clockLabelText: (view, time, adapter) => `Izaberi ${view}. ${time === null ? 'Vreme nije odabrano' : `Izabrano vreme je ${adapter.format(time, 'fullTime')}`}`,
  hoursClockNumberText: hours => `${hours} hours`,
  minutesClockNumberText: minutes => `${minutes} minuta`,
  secondsClockNumberText: seconds => `${seconds} sekundi`,
  // Digital clock labels
  selectViewText: view => `Izaberi ${view}`,
  // Calendar labels
  calendarWeekNumberHeaderLabel: 'Broj nedelje',
  calendarWeekNumberHeaderText: '#',
  calendarWeekNumberAriaLabelText: weekNumber => `Nedelja ${weekNumber}`,
  calendarWeekNumberText: weekNumber => `${weekNumber}`,
  // Open picker labels
  openDatePickerDialogue: (value, utils) => value !== null && utils.isValid(value) ? `Choose date, selected date is ${utils.format(value, 'fullDate')}` : 'Odaberi datum',
  openTimePickerDialogue: (value, utils) => value !== null && utils.isValid(value) ? `Choose time, selected time is ${utils.format(value, 'fullTime')}` : 'Odaberi vreme',
  fieldClearLabel: 'Obriši vrednost',
  // Table labels
  timeTableLabel: 'odaberi vreme',
  dateTableLabel: 'odaberi datum',
  // Field section placeholders
  fieldYearPlaceholder: params => 'G'.repeat(params.digitAmount),
  fieldMonthPlaceholder: params => params.contentType === 'letter' ? 'MMMM' : 'MM',
  fieldDayPlaceholder: () => 'DD',
  fieldWeekDayPlaceholder: params => params.contentType === 'letter' ? 'EEEE' : 'EE',
  fieldHoursPlaceholder: () => 'čč',
  fieldMinutesPlaceholder: () => 'mm',
  fieldSecondsPlaceholder: () => 'ss',
  fieldMeridiemPlaceholder: () => 'aa',
  // View names
  year: 'Godina',
  month: 'Mesec',
  day: 'Dan',
  weekDay: 'Dan u nedelji',
  hours: 'Sati',
  minutes: 'Minuti',
  seconds: 'Sekunde',
  meridiem: 'Pre podne/Posle podne',
  // Common
  empty: 'Prazno',
};
export const RS = getPickersLocalization(rsPickers);