import * as React from "react";
import {Grid, Typography} from '@mui/material';
import {useTranslate, useDataProvider, useNotify} from 'react-admin';
import {useSearchParams} from 'react-router-dom';
import {padDate} from "../utils/dateUtil";
import {useEffect} from "react";
import PrintIcon from "@mui/icons-material/Print";
import LoadingButton from "@mui/lab/LoadingButton";


// @ts-ignore
export const LoadingOrderListPrint = () => {
        const translate = useTranslate();
        const dataProvider = useDataProvider();
        const notify = useNotify();

        const [searchParams] = useSearchParams();
        const [orders, setOrders] = React.useState([]);


        let date = searchParams.get('date');
        let startDate = date !== null ? new Date(date) : new Date();
        const startDateFormatted = startDate.getFullYear() + "-" + padDate(startDate.getMonth() + 1) + "-" + padDate(startDate.getDate());

        useEffect(() => {
            dataProvider.getLoadingOrders(startDateFormatted)
                // @ts-ignore
                .then(response => {
                    setOrders(response.json['hydra:member'])
                })
                // @ts-ignore
                .catch(error => {
                    notify(`ra.notification.http_error`, {type: 'error'});
                });
        }, [date, dataProvider, startDateFormatted, notify]);
        const changesFrom = padDate(startDate.getDate()) + "." + padDate(startDate.getMonth() + 1) + "." + startDate.getFullYear();

        return <>

            <div className={"print-background"}>
                <Typography variant={"h5"}
                            sx={{textAlign: "center"}}>{translate('ra.loading_order.label')} {changesFrom}</Typography>
                <Grid container columns={12}>
                    <Grid item xs={6} className={"change-row-header"}>
                        {translate('ra.product.name')}
                    </Grid>
                    <Grid item xs={6} className={"change-row-header"}>
                        {translate('ra.changes.numberOfItems')}
                    </Grid>
                </Grid>


                {orders.map((order) => {
                    // @ts-ignore
                    return <>
                        <Grid container columns={12}>
                            <Grid item xs={6}>
                                {// @ts-ignore
                                    order.product.name}
                            </Grid>
                            <Grid item xs={6}>
                                {// @ts-ignore
                                    order.numOfItems}
                            </Grid>
                        </Grid>
                        <hr/>

                    </>
                })}
                {orders.length === 0 &&
                    <div>{translate('ra.loading_order.no_orders')}</div>
                }


            </div>


            <LoadingButton className={"changePrintBtn"} startIcon={<PrintIcon/>} variant="contained"
                           onClick={(e) => {
                               window.print()
                           }}>{translate('ra.changes.print.print')}</LoadingButton>

        </>
    }
;
