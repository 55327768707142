import {Loading, useListContext, useNotify, useRefresh, useTranslate} from "react-admin";
import {useForm} from "react-hook-form";
import {Grid} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import {ChangeListViewHeader} from "./changeListViewHeader";
import {ChangeListRow} from "./changeListRow";
import * as React from "react";
import SaveIcon from '@mui/icons-material/Save';
import {handleChanges} from "./changeListData";
import {Print} from "@mui/icons-material";
import StorageIcon from "@mui/icons-material/Storage";
import {getColumns} from "./functions";


const useFormRegister = () => {
    const {
        register,
        control,
        handleSubmit,
        setValue,
        unregister,
        getValues,
        formState: {errors}
    } = useForm({
        shouldUnregister: false,
        // @ts-ignore
        defaultValues: [],
    });

    return {register, control, handleSubmit, formSetValue: setValue, unregister, getValues, formErrors: errors};

}
// @ts-ignore
export const ChangesListView = (props) => {
    const notify = useNotify();
    const translate = useTranslate();
    const {data, isLoading, isFetching} = useListContext();
    const form = useFormRegister();

    const [submittedData, setSubmittedData] = React.useState({});
    const [sendingData, setSendingData] = React.useState(false);
    const refresh = useRefresh();
    if (isLoading || isFetching) {
        return <Loading/>;
    }

    return <>
        <Grid container spacing={0.5} columns={getColumns(props.uiColumns)}>
            <ChangeListViewHeader uiColumns={props.uiColumns}/>
            {
                // @ts-ignore
                data.map((item) => {
                    return <ChangeListRow {...form}
                                          uiColumns={props.uiColumns}
                                          item={item}
                                          key={`change_${item.id}`} submittedData={submittedData}/>

                })}

            <Grid container sx={{marginTop: 1}}>
                <Grid item xs={6}>
                    <LoadingButton loading={sendingData} startIcon={<SaveIcon/>} variant="contained"
                                   onClick={form.handleSubmit((data) => {
                                       setSendingData(true);
                                       handleChanges(data, notify, refresh, form.unregister, setSubmittedData, setSendingData);

                                   })}>{translate('ra.changes.save')}</LoadingButton>
                </Grid>
                <Grid item xs={6} container justifyContent={"flex-end"}>
                    <LoadingButton startIcon={<Print/>} variant="contained" color={"info"}
                                   href={"#/changes/print?sort_field=" + props.sort_field + "&sort_direction=" + props.sort_direction
                                       + "&date=" + props.urlDate}
                                   target={"_blank"}
                                   sx={{marginRight: 1}}
                    >{translate('ra.changes.print.print_order')}</LoadingButton>

                    <LoadingButton startIcon={<StorageIcon/>} variant="contained"
                                   href={"#/changes/loading?date=" + props.urlDate}

                    >{translate('ra.loading_order.label')}</LoadingButton>
                </Grid>

            </Grid>
        </Grid>
    </>
};