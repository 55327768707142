import {FieldGuesser} from "@api-platform/admin";
import {ShowGuesser} from "@api-platform/admin";
import {useTranslate, TextField, ReferenceField} from "react-admin";
import {ProductTitle} from "./productTitle";
// @ts-ignore
export const ProductShow = props => {
    const translate = useTranslate();
    return <>
        <ShowGuesser {...props} title={<ProductTitle/>}>
            <FieldGuesser source={"name"} label={translate('ra.product.name')}/>
            <FieldGuesser source={"barcode"} label={translate('ra.product.barcode')}/>
            <ReferenceField source={"productType"} reference="product_types" link={false}
                            label={translate('ra.product_type.name')}>
                <TextField source="name"/>
            </ReferenceField>
        </ShowGuesser>
    </>
};