import {
    Button,
    Card,
    CardContent,
    TextField,

} from "@mui/material";
import * as React from "react";
import {useTranslate, useRedirect} from "react-admin";
import {useForm} from "react-hook-form";

// @ts-ignore

// @ts-ignore
export const LoadingOrderFilter = (props) => {

    const translate = useTranslate();
    const {
        register,
        handleSubmit,
    } = useForm();
    const redirect = useRedirect();
    // @ts-ignore
    const onSubmit = (data) => {
        redirect('/changes/loading?date=' + data.date);
    }

    return <Card sx={{marginTop: 4}}>
        <CardContent className={"change-top-filters"}>
            <form onSubmit={handleSubmit(onSubmit)}>

                <TextField {...register('date')} label={translate('ra.changes.filter_date')} type="date"
                           defaultValue={props.startDateFormatted} sx={{minWidth: 150, maxWidth: 150}}/>


                <Button type="submit" variant="contained" sx={{
                    marginTop: 1,
                    marginLeft: 2,
                    lineHeight: 2.5
                }}>{translate('ra.changes.filter_change')}</Button>
            </form>


        </CardContent>
    </Card>
}