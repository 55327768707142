import * as React from "react";
import {Card, CardContent} from '@mui/material';
import {Title, useListController, ListContextProvider, useTranslate} from 'react-admin';
import {ChangesListView} from "./changeListView";
import {useSearchParams} from 'react-router-dom';
import {padDate} from "../utils/dateUtil";
import {ChangeListFilter} from "./changeListFilter";

// @ts-ignore
export const ChangeList = () => {
        const translate = useTranslate();
        const [searchParams] = useSearchParams();

        let date = searchParams.get('date');
        let startDate = date !== null ? new Date(date) : new Date();
        let endDate = new Date(startDate.toString());
        endDate.setDate(startDate.getDate() + 1);
        const startDateFormatted = startDate.getFullYear() + "-" + padDate(startDate.getMonth() + 1) + "-" + padDate(startDate.getDate());
        const endDateFormatted = endDate.getFullYear() + "-" + padDate(endDate.getMonth() + 1) + "-" + padDate(endDate.getDate());
        let sort_direction = searchParams.get('sort_direction');
        let sort_field = searchParams.get('sort_field');
        if (sort_field === null || sort_field === 'null') {
            sort_field = '';
        }
        if (sort_direction === null || sort_direction === 'null') {
            sort_direction = '';
        }


        const changes = useListController({
            resource: 'changes',
            perPage: 1000,
            filter: {
                'endAt[after]': startDateFormatted,
                'endAt[strictly_before]': endDateFormatted,
            },
            // @ts-ignore
            sort: {'field': '' + sort_field, 'order': '' + sort_direction}

        });

        const [open, setOpen] = React.useState(false);
        const [uiColumns, setUiColumns] = React.useState({
            orderNum: true,
            client: true,
            location: true,
            product: true,
            numOfItems: true,
            duration: true,
            nextChange: true,
            note: true
        });

        const filterProps = {
            open,
            setOpen,
            startDateFormatted,
            changes,
            sort_direction: sort_direction !== null ? sort_direction : '',
            sort_field: sort_field != null ? sort_field : '',
            start_date: date != null ? date : '',
            uiColumns,
            setUiColumns
        }

        return <>
            <ChangeListFilter {...filterProps}/>
            <Card sx={{marginTop: 1}}>
                <Title title={translate('ra.changes.label')}/>
                <CardContent>
                    <ListContextProvider value={changes}>
                        <ChangesListView sort_direction={sort_direction} sort_field={sort_field} urlDate={startDateFormatted} uiColumns={uiColumns}/>
                    </ListContextProvider>
                </CardContent>
            </Card>
        </>
    }
;
