import {useTranslate} from "react-admin";
import {Grid} from "@mui/material";
import * as React from "react";

// @ts-ignore
export const ChangeListViewHeader = (props) => {
    const translate = useTranslate();
    return <>
        {props.uiColumns.orderNum && <Grid item xs={1} className={"change-row-header"}>
            {translate('ra.changes.orderNum')}
        </Grid>
        }
        {props.uiColumns.client && <Grid item xs={1} className={"change-row-header"}>
            {translate('ra.client.name')}
        </Grid>
        }
        {props.uiColumns.location && <Grid item xs={2} className={"change-row-header"}>
            {translate('ra.location.name')}
        </Grid>
        }
        {props.uiColumns.product && <Grid item xs={2} className={"change-row-header"}>
            {translate('ra.product.name')} / {translate('ra.product_type.short_name')}
        </Grid>
        }

        {props.uiColumns.numOfItems && <Grid item xs={1} className={"change-row-header"}>
            {translate('ra.changes.numberOfItems')}
        </Grid>
        }
        {props.uiColumns.duration && <Grid item xs={1} className={"change-row-header"}>
            {translate('ra.changes.duration')}
        </Grid>
        }

        {props.uiColumns.nextChange && <Grid item xs={2} className={"change-row-header"}>
            {translate('ra.changes.nextChange')}
        </Grid>
        }
        {props.uiColumns.note && <Grid item xs={3} className={"change-row-header"}>
            {translate('ra.changes.note')}
        </Grid>
        }
        <Grid item xs={1} className={"change-row-header"}>
            {translate('ra.changes.action')}
        </Grid>
    </>
}