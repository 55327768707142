import {EditGuesser} from "@api-platform/admin";
import {LocationTagTitle} from "./locationTagTitle";
import {LocationTagInput} from "./locationTagInput";


// @ts-ignore
export const LocationTagEdit = props => {
    return <>
        <EditGuesser {...props} title={<LocationTagTitle/>}>
            <LocationTagInput {...props}/>
        </EditGuesser>
    </>
};