import {CreateGuesser} from "@api-platform/admin";
import {ClientContactFields} from "./clientContactFields";
import {useSearchParams} from "react-router-dom";
import {Loading, useGetOne} from "react-admin";
// @ts-ignore
export const ClientContactCreate = (props) => {
    const [searchParams] = useSearchParams();
    let defaultLocation = searchParams.get('location') ? searchParams.get('location') : null;
    let defaultClient = searchParams.get('client') ? searchParams.get('client') : null;
    let redirectLocation = "/client_contacts";


    const {data, isLoading, error} = useGetOne('locations', {id: defaultLocation ? defaultLocation : ""});
    if (isLoading || error) {
        return <Loading/>;
    }
    let createType = defaultClient !== null && defaultLocation !== null ? "createFromLocation" : "createEmpty";

    if (createType === "createFromLocation") {
        redirectLocation = "/locations/" + encodeURIComponent(defaultLocation !== null ? defaultLocation : "") + "/show"
    }
    return <>
        <CreateGuesser {...props} title={"Novi kontakt"} redirect={redirectLocation}>
            <ClientContactFields {...props} createType={createType} location={data} client={defaultClient}/>
        </CreateGuesser>
    </>
};