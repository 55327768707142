import {Grid, Typography} from "@mui/material";
import * as React from "react";
import {useTranslate} from "react-admin";
import {renderDate} from "../utils/dateUtil";
import {ReportContainer} from "./reportContainer";

// @ts-ignore
const parseDevices = (changes) => {
    let byDate = {};
    let total = {
        montage: 0,
        removed: 0,
    }
    // @ts-ignore
    changes.forEach(change => {
        const date = change.date;
        // @ts-ignore
        if (byDate[date] === undefined) {
            // @ts-ignore
            byDate[date] = {
                date: renderDate(change.date),
                montage: 0,
                removed: 0,
            };
        }
        // @ts-ignore
        if (change.key === 'devicesMontage') {
            // @ts-ignore
            byDate[date].montage += change.value;
            total.montage += change.value;
        } else if (change.key === 'devicesRemoved') {
            // @ts-ignore
            byDate[date].removed += change.value;
            total.removed += change.value;
        }
    });

    const ordered = Object.keys(byDate).sort().reduce(
        (obj, key) => {
            // @ts-ignore
            obj[key] = byDate[key];
            return obj;
        },
        {}
    );
    // @ts-ignore
    let parsed = [];
    Object.keys(ordered).forEach(key => {
        // @ts-ignore
        parsed.push(ordered[key]);
    });
    parsed.push({'date': 'total', ...total});

    // @ts-ignore
    return parsed;
}
// @ts-ignore
const parseOverall = (changes) => {
    let overall = {start: 0, end: 0};
    // @ts-ignore
    changes.forEach(change => {
        if (change.key === 'devicesPeriodStart') {
            overall.start = change.value;
        } else if (change.key === 'devicesPeriodEnd') {
            overall.end = change.value;
        }
    });

    return overall;
}

// @ts-ignore
export const ReportDevices = (props) => {
    const translate = useTranslate();
    const [metrics, setMetrics] = React.useState([]);
    const [overall, setOverall] = React.useState({start: 0, end: 0} as any);


    React.useEffect(() => {
        // @ts-ignore
        setMetrics(parseDevices(props.metrics));
        setOverall(parseOverall(props.metrics));
    }, [props.metrics]);

    return <ReportContainer {...props}>
        <Typography typography={"h5"}
                    align={"center"}>{translate('ra.reports.type_devices.daily')} {props.startDateHuman} - {props.endDateHuman}</Typography>
        <Grid container columns={12} sx={{marginTop: 2}}>
            <Grid item xs={4} className={"change-row-header"}>
                {translate('ra.reports.day')}
            </Grid>
            <Grid item xs={4} className={"change-row-header"}>
                {translate('ra.reports.type_devices.montage')}
            </Grid>
            <Grid item xs={4} className={"change-row-header"}>
                {translate('ra.reports.type_devices.removed')}
            </Grid>
        </Grid>

        {// @ts-ignore
            metrics.map((row) => {
                // @ts-ignore
                const className = row.date === 'total' ? 'report-row-total' : 'report-row';
                // @ts-ignore
                return <Grid container columns={12} key={//@ts-ignore
                    row.date}>
                    <Grid item xs={4} className={className}>
                        {// @ts-ignore
                            row.date === 'total' ? translate('ra.reports.type_devices.total') : row.date}
                    </Grid>
                    <Grid item xs={4} className={className}>
                        {// @ts-ignore
                            row.montage}
                    </Grid>
                    <Grid item xs={4} className={className}>
                        {// @ts-ignore
                            row.removed}
                    </Grid>
                </Grid>
            })
        }
        <Typography typography={"h5"} sx={{marginTop: 2}}
                    align={"center"}> {translate('ra.reports.type_devices.for_period')} {props.startDateHuman} - {props.endDateHuman}</Typography>

        <Grid container columns={12} sx={{marginTop: 2}}>
            <Grid item xs={6} className={"change-row-header"}>
                {translate('ra.reports.type_devices.item')}
            </Grid>
            <Grid item xs={6} className={"change-row-header"}>
                {translate('ra.reports.type_devices.value')}
            </Grid>

        </Grid>

        {metrics.length > 0 &&

            <span>
                <Grid container columns={12}>
                    <Grid item xs={6} className={'report-row'}>
                        {translate('ra.reports.type_devices.start')}
                    </Grid>
                    <Grid item xs={6} className={'report-row'}>
                        {// @ts-ignore
                            overall.end}
                    </Grid>
                </Grid>
                <Grid container columns={12}>
                    <Grid item xs={6} className={'report-row'}>
                        {translate('ra.reports.type_devices.end')}
                    </Grid>
                    <Grid item xs={6} className={'report-row'}>
                        {// @ts-ignore
                            overall.end}
                    </Grid>
                </Grid>
            </span>
        }


        {metrics.length === 0 &&
            <div>{translate('ra.reports.no_data')}</div>
        }


    </ReportContainer>
}