import {FieldGuesser} from "@api-platform/admin";
import {ShowGuesser} from "@api-platform/admin";
import {useTranslate} from "react-admin";
import {DeviceProductTitle} from "./deviceProductTitle";
// @ts-ignore
export const DeviceProductShow = props => {
    const translate = useTranslate();
    return <>
        <ShowGuesser {...props} title={<DeviceProductTitle/>}>
            <FieldGuesser source={"name"} label={translate('ra.device_product.name')}/>

        </ShowGuesser>
    </>
};