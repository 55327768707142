import {InputGuesser} from "@api-platform/admin";
import {useTranslate} from "react-admin";

// @ts-ignore
export const DeviceProductInput = (props) => {
    const translate = useTranslate();
    return <>
        <InputGuesser source={"name"} label={translate('ra.device_product.name')}/>

    </>
};