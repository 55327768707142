export const rsMessages = {
    ra: {
        empty: {
            list: 'Nema zapisa',
            list_no_results: 'Nema rezultata',
            list_filtered: 'Nema rezultata za ovaj filter',
            list_empty_filter: 'Nema zapisa za ovaj filter',
            list_empty_filter_with_name: 'Nema zapisa za ovaj filter: "%{name}"',
            list_empty: 'Nema zapisa',
            empty: 'Nema zapisa',
            empty_error: 'Greška',
            empty_error_description: 'Greška',
            empty_error_details: 'Greška',
            empty_error_details_with_status_code: 'Greška: %{status}',
            empty_error_details_with_status_code_and_description: 'Greška: %{status} %{detail}'
        },
        action: {
            edit: 'Izmeni',
            remove_all_filters: 'Ukloni sve filtere',
            remove_filter: 'Ukloni ovaj filter',
            save_filter: 'Sačuvaj ovaj filter',
        },
        navigation: {
            page_rows_per_page: 'Redova po strani:',
        },

        company: {
            name: 'Naziv firme',
            city: 'Grad',
            address: 'Adresa',
            postCode: 'Poštanski broj',
            pib: 'PIB',
            registrationNumber: 'Matični broj',
            email: 'Email',
            site: 'Sajt',
            phone: 'Telefon',
            workHours: 'Radno vreme',
            error_422: "Partner poseduje korisnike ili klijente. Obrišite ih pre brisanja klijenta."
        },
        message: {
            delete_content: 'Da li ste sigurni da želite da obrišete ovaj zapis?',
            delete_title: 'Brisanje zapisa',
        },
        saved_queries: {
            label: 'Sačuvani upiti',
            query_name: 'Naziv upita',
            new_label: 'Sačuvaj trenutni upit...',
            new_dialog_title: 'Sačuvaj trenutni upit kao',
            remove_label: 'Ukloni sačuvani upit',
            remove_label_with_name: 'Ukloni upit "%{name}"',
            remove_dialog_title: 'Ukloni sačuvani upit?',
            remove_message: 'Da li ste sigurni da želite da uklonite ovaj zapis iz liste sačuvanih upita?',

        },
        product_type: {
            name: 'Naziv tipa proizvoda',
            short_name: 'Tip proizvoda',
            error_422: 'Tip proizvoda poseduje proizvode. Obrišite ih pre brisanja tipa proizvoda.',
        },
        product: {
            name: 'Naziv proizvoda',
            barcode: 'Barkod',
            active: 'Aktivan',
            unavailable: ' je nedostupan',
            error_422: 'Proizvod je aktivan. Obrišite ih pre brisanja proizvoda.',
            original: 'Prethodno',
            new: 'Novo',
        },
        client: {
            name: 'Naziv firme',
            city: 'Grad',
            address: 'Adresa',
            postCode: 'Poštanski broj',
            pib: 'PIB',
            registrationNumber: 'Matični broj',
            email: 'Email',
            site: 'Sajt',
            phone: 'Telefon',
            workHours: 'Radno vreme',
            contact: 'Kontakt osoba',
            error_422: "Klijent poseduje lokacije ili kontakte. Obrišite ih pre brisanja klijenta."
        },
        client_contact: {
            name: 'Ime i prezime',
            email: 'Email',
            phone: 'Telefon',
            workHours: 'Radno vreme',
            title: 'Pozicija',
        },
        location: {
            name: 'Naziv lokacije',
            city: 'Grad',
            address: 'Adresa',
            postCode: 'Poštanski broj',
            workingHours: 'Radno vreme',
            error_422: 'Lokacija poseduje uređaje ili proizvode. Obrišite ih pre brisanja lokacije.',
        },
        user: {
            name: 'Ime i prezime',
            email: 'Email',
            role: 'Uloga',
            username: 'Korisničko ime',
            password: 'Lozinka',
            login_as: 'Prijavi se kao',

        },
        page: {
            create: 'Kreiraj %{name}',
            dashboard: 'Kontrolna tabla',
            edit: '%{name} %{recordRepresentation}',
            error: 'Greška',
            list: '%{name}',
            loading: 'Učitavanje',
            not_found: 'Nije pronađeno',
            show: '%{name} %{recordRepresentation}',
            empty: 'Nema zapisa',
            invite: 'Da li želite da kreirate?',
        },
        changes: {
            startAt: 'Datum početka',
            label: 'Zamene',
            numberOfItems: 'Broj stavki',
            duration: 'Trajanje',
            nextChange: 'Sledeća zamena',
            note: 'Napomena',
            action: 'Akcija',
            success: 'Podaci su uspešno sačuvani',
            error_500: 'Server nije dostupan. Molimo pokušajte kasnije.',
            error_400: 'Greška u poslatim podacima.',
            save: 'Sačuvaj',
            filter_date: 'Datum',
            filter_change: 'Promeni',
            remove_change_success: 'Uspešno uklonjen proizvod',
            warning: 'Niste napravili promene statusa',
            filedsNumOfItemsMin: 'Broj stavki mora biti veći od 0',
            fields: {
                numOfItems: {
                    min: 'Broj stavki mora biti veći od 0',
                    number: 'Broj stavki mora biti broj',
                },
                duration: {
                    min: 'Trajanje mora biti veće od 0',
                    number: 'Trajanje mora biti broj',
                },
                orderNum: {
                    min: 'Redosled mora biti veći od 0',
                    number: 'Redosled mora biti broj',
                },
                product: {
                    required: 'Proizvod je obavezan',
                },
                endAt: {
                    required: 'Datum je obavezan',
                    format: 'Datum mora biti u formatu YYYY-MM-DD',
                    future: 'Datum mora biti u budućnosti',
                }

            },
            print : {
                product_and_type: 'Proizvod i tip',
                print_title: 'Zamene za ',
                print: 'Štampaj',
                print_order: 'Štampaj nalog',
            },
            orderNum: 'Redosled',
            dialog: {
                title: 'Odabir kolona za prikaz',
                work_order: 'Radni nalog',
                print_order: 'Štampani nalog',
                fields: {
                    orderNum: 'Redosled',
                    product: 'Proizvod',
                    numOfItems: 'Broj stavki',
                    duration: 'Trajanje',
                    nextChange: 'Sledeća zamena',
                    note: 'Napomena',
                    client: 'Naziv firme',
                    location: 'Lokacija',
                    close: 'Zatvori',
                }
            }
        },
        statuses: {
            label: 'Statusi',
            label_add: 'Status',
            active: 'Aktivan',
            changed: 'Promenjen',
            delayed: 'Odložen',
            deleted: 'Demonstriran',
        },
        device_product: {
            name: 'Naziv uređaja',
            error_422: 'Uređaj je montiran. Obrišite ih pre brisanja uređaja.',
        },
        location_tag: {
            name: 'Tag',
        },
        loading_order: {
            label: 'Nalog za utovar',
            no_orders: 'Nema naloga za utovar',
            print : {
                print_order: 'Štampaj nalog',
            },

        },
        device: {
            montage_date: 'Datum montaže',
            loaned: 'Revers',
            id: 'ID',
            note: 'Napomena',
        },
        reports: {
            label: 'Izveštaji',
            start_date: 'Datum početka',
            end_date: 'Datum završetka',
            filter_change: 'Promeni',
            type: 'Tip',
            product: 'Proizvod',
            changes: 'Zamene',
            clients: 'Klijenti',
            devices: 'Uređaji',
            partners: 'Partneri',
            product_types: 'Tip proizvoda',
            print: 'Štampaj',
            report_print: 'Izveštaj za štampu',
            day: 'Datum',
            no_data: 'Nema podataka',
            type_changes: {
                active: 'Aktivno',
                delayed: 'Odloženo',
                deleted: 'Demontirano',
                changed: 'Promenjeno',
                total: 'Ukupno',
                daily: 'Dnevni izveštaj',
            },
            type_clients: {
                start: 'Ukupan broj klijenata na početaku perioda',
                end: 'Ukupan broj klijenata na kraj perioda',
                in_period: 'Broj klijenta sa zamena u periodu',
                item: 'Stavka',
                value: 'Vrednost',
                for_period: 'Izveštaj za period',
            },
            type_devices: {
                montage: 'Broj montiranih uređaja',
                removed: 'Broj demontiranih uređaja',
                start: 'Ukupan broj montiranih uređaja na početku perioda',
                end: 'Ukupan broj montiranih uređaja na kraju perioda',
                total: 'Ukupno',
                item: 'Stavka',
                value: 'Vrednost',
                daily: 'Dnevni izveštaj',
                for_period: 'Izveštaj za period',
            },
            type_products: {
                product: 'Proizvod',
                value: 'Vrednost',
                total: 'Ukupno',
                item: 'Stavka',
                daily: 'Dnevni izveštaj',
                for_period: 'Izveštaj za period',

            },
            type_product_types: {
                category: 'Kategorija',
                value: 'Vrednost',
                total: 'Ukupno',
                item: 'Stavka',
                daily: 'Dnevni izveštaj',
                for_period: 'Izveštaj za period',
            },
            type_partners: {
                partner: 'Partner',
                for_period: 'Izveštaj za period',
                value: 'Vrednost',
                item: 'Stavka',
                active: 'Aktivno',
                delayed: 'Odloženo',
                deleted: 'Demontirano',
                changed: 'Promenjeno',
                total: 'Ukupno',
                start: 'Ukupan broj klijenata na početaku perioda',
                end: 'Ukupan broj klijenata na kraj perioda',
                in_period: 'Broj klijenta sa zamena u periodu',
            }
        },
        device_colors: {
            name: 'Naziv boje',
            label: 'Boje aparata',
            new_item: 'Nova boja',
            error_422: 'Boja je dodeljena uređaju. Obrišite ih pre brisanja boje.',
        }

    }
}