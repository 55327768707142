import {CreateGuesser} from "@api-platform/admin";
import {LocationFields} from "./locationFields";
// @ts-ignore
export const LocationCreate = (props) => {
    return <>
        <CreateGuesser {...props} title={"Nova lokacija"}>

            <LocationFields {...props} create={true}/>
        </CreateGuesser>
    </>
};