import {ClientTitle} from "./clientTitle";
import {ClientFields} from "./clientFields";
import {Edit, SimpleForm} from 'react-admin';
import {ScentToolbar} from "../layout/ScentToolbar";
import {DeleteClient} from "./deleteClient";


// @ts-ignore
export const ClientEdit = (props) => {
    const deleteButton = <DeleteClient/>;
    return <>
        <Edit title={<ClientTitle/>}>
            <SimpleForm toolbar={<ScentToolbar deleteButton={deleteButton}/>}>
                <ClientFields {...props}/>
            </SimpleForm>
        </Edit>

    </>
};