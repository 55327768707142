import {Card, CardContent} from "@mui/material";
import {ReportPrintButton} from "./reportPrintButton";
import {FinalPrintButton} from "./finalPrintButton";
import * as React from "react";
// @ts-ignore
export const ReportContainer = (props) => {

    if (props.print) {
        return <>
            <div className={"print-background"}>
                {props.children}
                <FinalPrintButton/>

            </div>
        </>
    } else {
        return <Card sx={{marginTop: 1}}>
            <CardContent>
                {props.children}
                <ReportPrintButton startDateFormatted={props.startDateFormatted}
                                   endDateFormatted={props.endDateFormatted}
                                   reportType={props.reportType}/>
            </CardContent>
        </Card>
    }
}