import {FieldGuesser} from "@api-platform/admin";
import {ShowGuesser} from "@api-platform/admin";
import {
    useTranslate,
    UrlField,
    ReferenceArrayField,
    Datagrid,
    TextField,
    ShowButton, DeleteButton,
} from "react-admin";
import {ClientTitle} from "./clientTitle";

//  @ts-ignore
export const ClientShow = props => {
    const translate = useTranslate();
    return <>
        <ShowGuesser {...props} title={<ClientTitle/>}>

            <FieldGuesser source={"name"} label={translate('ra.client.name')}/>
            <FieldGuesser source={"address"} label={translate('ra.client.address')}/>
            <FieldGuesser source={"city"} label={translate('ra.client.city')}/>
            <FieldGuesser source={"postCode"} label={translate('ra.client.postCode')}/>
            <FieldGuesser source={"pib"} label={translate('ra.client.pib')}/>
            <FieldGuesser source={"registrationNumber"} label={translate('ra.client.registrationNumber')}/>
            <FieldGuesser source={"phone"} label={translate('ra.client.phone')}/>
            <FieldGuesser source={"email"} label={translate('ra.client.email')}/>
            <UrlField source={"site"} label={translate('ra.client.site')}/>
            <FieldGuesser source={"workingHours"} label={translate('ra.client.workHours')}/>
            <h3>Kontakt osobe</h3>
            <ReferenceArrayField source={"contacts"} reference={"client_contacts"} label={false}>
                <Datagrid bulkActionButtons={false}>
                    <TextField source="name" label={translate('ra.client_contact.name')}/>
                    <TextField source="title" label={translate('ra.client_contact.title')}/>
                    <TextField source="phone" label={translate('ra.client_contact.phone')}/>
                    <TextField source="email" label={translate('ra.client_contact.email')}/>
                    <ShowButton />
                    <DeleteButton mutationMode={"optimistic"}/>
                </Datagrid>
            </ReferenceArrayField >

            <h3>Lokacije</h3>
            <ReferenceArrayField source={"locations"} reference={"locations"} label={false}>
                <Datagrid bulkActionButtons={false}>
                    <TextField source="name" label={translate('ra.location.name')}/>
                    <TextField source="city" label={translate('ra.location.city')}/>
                    <TextField source="address" label={translate('ra.location.address')}/>

                    <ShowButton />
                    {/*<DeleteButton mutationMode={"optimistic"}/>*/}
                </Datagrid>
            </ReferenceArrayField >

        </ShowGuesser>
    </>
};