import {FieldGuesser, ListGuesser} from "@api-platform/admin";
import {useTranslate} from 'react-admin';
// @ts-ignore
export const DeviceColorList = props => {
    const translate = useTranslate();
    return <>
        <ListGuesser {...props} title={translate('ra.device_colors.label')} exporter={false}>
            <FieldGuesser source={"name"} label={translate('ra.device_colors.name')}/>
        </ListGuesser>
    </>
};