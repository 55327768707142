const columnSize = {
    orderNum: 1,
    client: 1,
    location: 2,
    product: 2,
    numOfItems: 1,
    duration: 1,
    nextChange: 2,
    note: 3
}

export const getColumns = (columns: object) => {
    let c: number = 1;
    Object.keys(columns).forEach((column) => {
        // @ts-ignore
        if (columns[column] && columnSize[column]) {
            // @ts-ignore
            c += columnSize[column];
        }
    });
    return c;
}