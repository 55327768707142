import {Typography} from "@mui/material";

// @ts-ignore
export const validationErrorCallback = (data) => {
    const message =data.message;
    return <ValidationError message={message}/>
}
// @ts-ignore
export const ValidationError = (props) => {
    return <Typography color={"error"} fontSize={14}>
        {props.message}
    </Typography>
}