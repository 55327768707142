import LoadingButton from "@mui/lab/LoadingButton";
import PrintIcon from "@mui/icons-material/Print";
import * as React from "react";
import {useTranslate} from "react-admin";

// @ts-ignore
export const FinalPrintButton = (props) => {
    const translate = useTranslate();
    return <>
        <LoadingButton className={"changePrintBtn"} startIcon={<PrintIcon/>} variant="contained"
                       onClick={(e) => {
                           window.print()
                       }}>{translate('ra.reports.print')}</LoadingButton>
    </>;
}