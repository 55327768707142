import {InputGuesser} from "@api-platform/admin";
import {PasswordInput, ReferenceInput, SelectArrayInput, SelectInput, useTranslate} from "react-admin";

// @ts-ignore
export const UserInput = (props) => {
    const translate = useTranslate();
    return <>

        <InputGuesser source={"username"} label={translate('ra.user.username')}/>
        <InputGuesser source={"email"} label={translate('ra.user.email')}/>
        <PasswordInput  source={"plainPassword"} label={translate('ra.user.password')}/>
        <InputGuesser source={"name"} label={translate('ra.user.name')}/>
        <SelectArrayInput source="roles" choices={[
            { id: 'ROLE_ADMIN', name: 'Admin' },
            { id: 'ROLE_USER', name: 'Korisnik' }
        ]} label={translate('ra.user.role')} />



        <ReferenceInput  source={"partner"} reference="partners">
            <SelectInput optionText="name" label={translate('ra.company.name')}/>
        </ReferenceInput>
    </>
};