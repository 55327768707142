import {Loading, useListContext, useTranslate} from "react-admin";

import {Grid} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import {ChangeListRowPrint} from "./changeListRowPrint";
import * as React from "react";
import PrintIcon from '@mui/icons-material/Print';
import {ChangeListViewHeaderPrint} from "./changeListViewHeaderPrint";

// @ts-ignore
export const ChangesListViewPrint = (props) => {
    const translate = useTranslate();
    const {data, isLoading, isFetching} = useListContext();

    if (isLoading || isFetching) {
        return <Loading/>;
    }

    return <>
        <Grid container spacing={0.5} columns={12}>
            <ChangeListViewHeaderPrint/>
            {
                // @ts-ignore
                data.map((item) => {
                    return <ChangeListRowPrint
                                          item={item}
                                          key={`change_${item.id}`}/>

                })}
            <LoadingButton className={"changePrintBtn"} startIcon={<PrintIcon/>} variant="contained"
                           onClick={(e) => {
                               window.print()
                           }}>{translate('ra.changes.print.print')}</LoadingButton>
        </Grid>
    </>
};