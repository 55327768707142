import {Grid, Typography} from "@mui/material";
import * as React from "react";
import {useTranslate} from "react-admin";
import {renderDate} from "../utils/dateUtil";
import {ReportContainer} from "./reportContainer";

// @ts-ignore
const parseChanges = (changes) => {
    let byDate = {};
    let total = {
        active: 0,
        changed: 0,
        delayed: 0,
        deleted: 0,
        total: 0,
    }
    // @ts-ignore
    changes.forEach(change => {
        const date = change.date;
        // @ts-ignore
        if (byDate[date] === undefined) {
            // @ts-ignore
            byDate[date] = {
                date: renderDate(change.date),
                active: 0,
                changed: 0,
                delayed: 0,
                deleted: 0,
                total: 0,
            };
        }
        // @ts-ignore
        if (change.key === 'active') {
            // @ts-ignore
            byDate[date].active += change.value;
            total.active += change.value;
        } else if (change.key === 'changed') {
            // @ts-ignore
            byDate[date].changed += change.value;
            total.changed += change.value;
        } else if (change.key === 'delayed') {
            // @ts-ignore
            byDate[date].delayed += change.value;
            total.delayed += change.value;
            // @ts-ignore
        } else if (change.key === 'deleted') {
            // @ts-ignore
            byDate[date].deleted += change.value;
            total.deleted += change.value;
        }
        // @ts-ignore
        byDate[date].total += change.value;
        total.total += change.value;
    });

    const ordered = Object.keys(byDate).sort().reduce(
        (obj, key) => {
            // @ts-ignore
            obj[key] = byDate[key];
            return obj;
        },
        {}
    );
    // @ts-ignore
    let parsed = [];
    Object.keys(ordered).forEach(key => {
        // @ts-ignore
        parsed.push(ordered[key]);
    });
    parsed.push({'date': 'total', ...total});

    // @ts-ignore
    return parsed;
}
// @ts-ignore
export const ReportChanges = (props) => {
    const translate = useTranslate();
    const [metrics, setMetrics] = React.useState([]);

    React.useEffect(() => {
        // @ts-ignore
        setMetrics(parseChanges(props.metrics));
    }, [props.metrics]);

    return <ReportContainer {...props}>
        <Typography typography={"h5"}
                    align={"center"}> {translate('ra.reports.type_changes.daily')} {props.startDateHuman} - {props.endDateHuman}</Typography>
        <Grid container columns={12} sx={{marginTop: 2}}>
            <Grid item xs={2} className={"change-row-header"}>
                {translate('ra.reports.day')}
            </Grid>
            <Grid item xs={2} className={"change-row-header"}>
                {translate('ra.reports.type_changes.active')}
            </Grid>
            <Grid item xs={2} className={"change-row-header"}>
                {translate('ra.reports.type_changes.changed')}
            </Grid>
            <Grid item xs={2} className={"change-row-header"}>
                {translate('ra.reports.type_changes.delayed')}
            </Grid>
            <Grid item xs={2} className={"change-row-header"}>
                {translate('ra.reports.type_changes.deleted')}
            </Grid>
            <Grid item xs={2} className={"change-row-header"}>
                {translate('ra.reports.type_changes.total')}
            </Grid>
        </Grid>

        {// @ts-ignore
            metrics.map((row) => {
                // @ts-ignore
                const className = row.date === 'total' ? 'report-row-total' : 'report-row';
                // @ts-ignore
                return <Grid container columns={12} key={//@ts-ignore
                    row.date}>
                    <Grid item xs={2} className={className}>
                        {// @ts-ignore
                            row.date === 'total' ? translate('ra.reports.type_changes.total') : row.date}
                    </Grid>
                    <Grid item xs={2} className={className}>
                        {// @ts-ignore
                            row.active}
                    </Grid>
                    <Grid item xs={2} className={className}>
                        {// @ts-ignore
                            row.changed}
                    </Grid>
                    <Grid item xs={2} className={className}>
                        {// @ts-ignore
                            row.delayed}
                    </Grid>
                    <Grid item xs={2} className={className}>
                        {// @ts-ignore
                            row.deleted}
                    </Grid>
                    <Grid item xs={2} className={className}>
                        {// @ts-ignore
                            row.total}
                    </Grid>
                </Grid>

            })}

        {metrics.length === 0 &&
            <div>{translate('ra.reports.no_data')}</div>
        }


    </ReportContainer>
}