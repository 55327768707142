import {Grid, Typography} from "@mui/material";
import * as React from "react";
import {useTranslate} from "react-admin";
import {renderDate} from "../utils/dateUtil";
import {ReportContainer} from "./reportContainer";

// @ts-ignore
const parseChanges = (changes) => {
    let byDate = {};
    let total = 0;

    // @ts-ignore
    changes.forEach(change => {
        const date = change.date;
        // @ts-ignore
        if (byDate[date] === undefined) {
            // @ts-ignore
            byDate[date] = {
                date: renderDate(change.date),
                products: [],
            };
        }
        // @ts-ignore
        if (change.key === 'productsInPeriod') {
            // @ts-ignore
            byDate[date].products.push({name: change.name, value: change.value});
            total += change.value;
        }

    });

    const ordered = Object.keys(byDate).sort().reduce(
        (obj, key) => {
            // @ts-ignore
            obj[key] = byDate[key];
            return obj;
        },
        {}
    );
    // @ts-ignore
    let parsed = [];
    Object.keys(ordered).forEach(key => {
        // @ts-ignore
        ordered[key].products.forEach(product => {
            // @ts-ignore
            parsed.push({date: ordered[key].date, ...product});
        });
    });
    parsed.push({'date': 'total', name: '', value: total});

    // @ts-ignore
    return parsed;
}
// @ts-ignore
const parseOverall = (changes) => {
    // @ts-ignore
    let overall = [];
    let byName = {};
    // @ts-ignore
    changes.forEach(change => {
        if (change.key === 'productsInPeriod') {
            const name = change.name;
            // @ts-ignore
            if (byName[name] === undefined) {
                // @ts-ignore
                byName[name] = 0;
            }
            // @ts-ignore
            byName[name] += change.value;
        }
    });
    Object.keys(byName).forEach(key => {
        // @ts-ignore
        overall.push({name: key, value: byName[key]});
    });

    // @ts-ignore
    return overall;
}
// @ts-ignore
export const ReportProducts = (props) => {
    const translate = useTranslate();
    const [metrics, setMetrics] = React.useState([]);
    const [overall, setOverall] = React.useState([]);

    React.useEffect(() => {
        // @ts-ignore
        setMetrics(parseChanges(props.metrics));
        // @ts-ignore
        setOverall(parseOverall(props.metrics));

    }, [props.metrics]);


    // @ts-ignore
    return <ReportContainer {...props}>
        <Typography typography={"h5"}
                    align={"center"}> {translate('ra.reports.type_product_types.daily')} {props.startDateHuman} - {props.endDateHuman}</Typography>
        <Grid container columns={12} sx={{marginTop: 2}}>
            <Grid item xs={4} className={"change-row-header"}>
                {translate('ra.reports.day')}
            </Grid>
            <Grid item xs={4} className={"change-row-header"}>
                {translate('ra.reports.type_product_types.category')}
            </Grid>
            <Grid item xs={4} className={"change-row-header"}>
                {translate('ra.reports.type_product_types.value')}
            </Grid>

        </Grid>

        {// @ts-ignore
            metrics.map((row) => {
                // @ts-ignore
                const className = row.date === 'total' ? 'report-row-total' : 'report-row';
                // @ts-ignore
                return <Grid container columns={12} key={//@ts-ignore
                    row.date + row.name}>
                    <Grid item xs={4} className={className}>
                        {// @ts-ignore
                            row.date === 'total' ? translate('ra.reports.type_product_types.total') : row.date}
                    </Grid>
                    <Grid item xs={4} className={className}>
                        {// @ts-ignore
                            row.name}
                    </Grid>
                    <Grid item xs={4} className={className}>
                        {// @ts-ignore
                            row.value}
                    </Grid>
                </Grid>
            })
        }

        <Typography typography={"h5"} sx={{marginTop: 2}}
                    align={"center"}> {translate('ra.reports.type_product_types.for_period')} {props.startDateHuman} - {props.endDateHuman}</Typography>
        <Grid container columns={12} sx={{marginTop: 2}}>
            <Grid item xs={6} className={"change-row-header"}>
                {translate('ra.reports.type_product_types.category')}
            </Grid>
            <Grid item xs={6} className={"change-row-header"}>
                {translate('ra.reports.type_product_types.total')}
            </Grid>

        </Grid>

        {// @ts-ignore
            overall.map((row) => {

                return <Grid container columns={12} key={//@ts-ignore
                    row.name}>
                    <Grid item xs={6} className={'report-row'}>
                        {// @ts-ignore
                            row.name}
                    </Grid>
                    <Grid item xs={6} className={'report-row'}>
                        {// @ts-ignore
                            row.value}
                    </Grid>
                </Grid>

            })
        }

{
    metrics.length === 0 &&
    <div>{translate('ra.reports.no_data')}</div>
}


</ReportContainer>
}