import {Grid, Typography} from "@mui/material";
import * as React from "react";
import {useTranslate} from "react-admin";
import {ReportContainer} from "./reportContainer";

// @ts-ignore
const parseChanges = (changes) => {
    let byPartner = {};
    let total = {
        active: 0,
        changed: 0,
        delayed: 0,
        deleted: 0,
        total: 0,
        clientInPeriod: 0,
        clientStart: 0,
        clientEnd: 0,
    }
    // @ts-ignore
    changes.forEach(change => {
        const name = change.name;
        // @ts-ignore
        if (byPartner[name] === undefined) {
            // @ts-ignore
            byPartner[name] = {
                name: name,
                active: 0,
                changed: 0,
                delayed: 0,
                deleted: 0,
                total: 0,
                clientInPeriod: 0,
                clientStart: 0,
                clientEnd: 0,
            };
        }
        if (change.key.indexOf('partnerChangesInPeriod-') !== -1) {

            const key = change.key.replace('partnerChangesInPeriod-', '');
            // @ts-ignore
            if (key === 'active') {
                // @ts-ignore
                byPartner[name].active += change.value;
                total.active += change.value;
            } else if (key === 'changed') {
                // @ts-ignore
                byPartner[name].changed += change.value;
                total.changed += change.value;
            } else if (key === 'delayed') {
                // @ts-ignore
                byPartner[name].delayed += change.value;
                total.delayed += change.value;
                // @ts-ignore
            } else if (key === 'deleted') {
                // @ts-ignore
                byPartner[name].deleted += change.value;
                total.deleted += change.value;
            }
            // @ts-ignore
            byPartner[name].total += change.value;
            total.total += change.value;
        } else if (change.key === 'partnerClientPeriodStart') {
            // @ts-ignore
            byPartner[name].clientStart += change.value;
            total.clientStart += change.value;
        } else if (change.key === 'partnerClientPeriodEnd') {
            // @ts-ignore
            byPartner[name].clientEnd += change.value;
            total.clientEnd += change.value;
        } else if (change.key === 'partnerClientInPeriod') {
            // @ts-ignore
            byPartner[name].clientInPeriod += change.value;
            total.clientInPeriod += change.value;
        }
    })


    // @ts-ignore
    let parsed = [];
    Object.keys(byPartner).forEach(key => {
        // @ts-ignore
        parsed.push(byPartner[key]);
    });
    parsed.push({'name': 'total', ...total});

    // @ts-ignore
    return parsed;
}
// @ts-ignore
export const ReportPartner = (props) => {
    const translate = useTranslate();
    const [metrics, setMetrics] = React.useState([]);

    React.useEffect(() => {
        // @ts-ignore
        setMetrics(parseChanges(props.metrics));
    }, [props.metrics]);

    return <ReportContainer {...props}>
        <Typography typography={"h5"}
                    align={"center"}> {translate('ra.reports.type_partners.for_period')} {props.startDateHuman} - {props.endDateHuman}</Typography>
        <Grid container columns={13} sx={{marginTop: 2}}>
            <Grid item xs={2} className={"change-row-header"}>
                {translate('ra.reports.type_partners.partner')}
            </Grid>
            <Grid item xs={1} className={"change-row-header"}>
                {translate('ra.reports.type_partners.active')}
            </Grid>
            <Grid item xs={1} className={"change-row-header"}>
                {translate('ra.reports.type_partners.changed')}
            </Grid>
            <Grid item xs={1} className={"change-row-header"}>
                {translate('ra.reports.type_partners.delayed')}
            </Grid>
            <Grid item xs={1} className={"change-row-header"}>
                {translate('ra.reports.type_partners.deleted')}
            </Grid>
            <Grid item xs={1} className={"change-row-header"}>
                {translate('ra.reports.type_partners.total')}
            </Grid>
            <Grid item xs={2} className={"change-row-header"}>
                {translate('ra.reports.type_partners.in_period')}
            </Grid>
            <Grid item xs={2} className={"change-row-header"}>
                {translate('ra.reports.type_partners.start')}
            </Grid>
            <Grid item xs={2} className={"change-row-header"}>
                {translate('ra.reports.type_partners.end')}
            </Grid>

        </Grid>

        {// @ts-ignore
            metrics.map((row) => {
                // @ts-ignore
                const className = row.name === 'total' ? 'report-row-total' : 'report-row';
                // @ts-ignore
                return <Grid container columns={13}
                             key={//@ts-ignore
                                 row.name}>
                    <Grid item xs={2} className={className}>
                        {// @ts-ignore
                            row.name === 'total' ? translate('ra.reports.type_partners.total') : row.name}
                    </Grid>
                    <Grid item xs={1} className={className}>
                        {// @ts-ignore
                            row.active}
                    </Grid>
                    <Grid item xs={1} className={className}>
                        {// @ts-ignore
                            row.changed}
                    </Grid>
                    <Grid item xs={1} className={className}>
                        {// @ts-ignore
                            row.delayed}
                    </Grid>
                    <Grid item xs={1} className={className}>
                        {// @ts-ignore
                            row.deleted}
                    </Grid>
                    <Grid item xs={1} className={className}>
                        {// @ts-ignore
                            row.total}
                    </Grid>
                    <Grid item xs={2} className={className}>
                        {// @ts-ignore
                            row.clientInPeriod}
                    </Grid>
                    <Grid item xs={2} className={className}>
                        {// @ts-ignore
                            row.clientStart}
                    </Grid>
                    <Grid item xs={2} className={className}>
                        {// @ts-ignore
                            row.clientEnd}
                    </Grid>
                </Grid>

            })}

        {metrics.length === 0 &&
            <div>{translate('ra.reports.no_data')}</div>
        }


    </ReportContainer>
}