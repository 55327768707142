import {Edit, SimpleForm} from "react-admin";
import {ScentToolbar} from "../layout/ScentToolbar";
import {DeleteProduct} from "./deleteProduct";
import { ProductTitle } from "./productTitle";
import { ProductInput } from "./productInput";


// @ts-ignore
export const ProductEdit = (props) => {
    const deleteButton = <DeleteProduct/>;
    return <>
        <Edit title={<ProductTitle/>}>
            <SimpleForm toolbar={<ScentToolbar deleteButton={deleteButton}/>}>
                <ProductInput {...props}/>
            </SimpleForm>
        </Edit>

    </>
};