import LoadingButton from "@mui/lab/LoadingButton";
import {Print} from "@mui/icons-material";
import {Grid} from "@mui/material";
import * as React from "react";
import {useTranslate} from "react-admin";
// @ts-ignore
export const ReportPrintButton = (props) => {
    const translate = useTranslate();
    return <Grid container sx={{marginTop: 4}}>
        <LoadingButton startIcon={<Print/>} variant="contained" color={"info"}
                       href={'#/reports/print?startDate=' + props.startDateFormatted + '&endDate=' + props.endDateFormatted + '&report_type=' + props.reportType}
                       target={"_blank"}
                       sx={{marginRight: 1}}
        >{translate('ra.reports.report_print')}</LoadingButton>
    </Grid>;
}