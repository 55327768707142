import * as React from "react";
import {useTranslate} from "react-admin";
import {Button} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import DialogActions from "@mui/material/DialogActions";

// @ts-ignore
export const ColumnChooser = (props) => {
    const open = props.open;
    const uiState = props.uiColumns;
    const setUiState = props.setUiColumns;
    const [printState, setPrintState] = React.useState({
        orderNum: true,
        client: true,
        location: true,
        product: true,
        numOfItems: true,
        duration: true,
        nextChange: true,
        note: true
    });
    const translate = useTranslate();

    const handleClose = () => {
        props.setOpen(false);
    };

    const handleUiChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUiState({
            ...uiState,
            [event.target.name]: event.target.checked,
        });
    };
    const handlePrintChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPrintState({
            ...printState,
            [event.target.name]: event.target.checked,
        });
    };


    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{translate('ra.changes.dialog.title')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <FormControl sx={{m: 3}} component="fieldset" variant="standard">
                            <FormLabel component="legend">{translate('ra.changes.dialog.work_order')}</FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox checked={uiState.orderNum} onChange={handleUiChange}
                                                       name="orderNum"/>}
                                    label={translate('ra.changes.dialog.fields.orderNum')}/>
                                <FormControlLabel control={<Checkbox checked={uiState.client} onChange={handleUiChange}
                                                                     name="client"/>}
                                                  label={translate('ra.changes.dialog.fields.client')}/>
                                <FormControlLabel control={<Checkbox checked={uiState.location} onChange={handleUiChange}
                                                                     name="location"/>}
                                                  label={translate('ra.changes.dialog.fields.location')}/>
                                <FormControlLabel control={<Checkbox checked={uiState.product} onChange={handleUiChange}
                                                                     name="product"/>}
                                                  label={translate('ra.changes.dialog.fields.product')}/>
                                <FormControlLabel control={<Checkbox checked={uiState.numOfItems} onChange={handleUiChange}
                                                                     name="numOfItems"/>}
                                                  label={translate('ra.changes.dialog.fields.numOfItems')}/>
                                <FormControlLabel control={<Checkbox checked={uiState.duration} onChange={handleUiChange}
                                                                     name="duration"/>}
                                                  label={translate('ra.changes.dialog.fields.duration')}/>
                                <FormControlLabel control={<Checkbox checked={uiState.nextChange} onChange={handleUiChange}
                                                                     name="nextChange"/>}
                                                  label={translate('ra.changes.dialog.fields.nextChange')}/>
                                <FormControlLabel control={<Checkbox checked={uiState.note} onChange={handleUiChange}
                                                                     name="note"/>}
                                                  label={translate('ra.changes.dialog.fields.note')}/>

                            </FormGroup>
                        </FormControl>
                        <FormControl sx={{m: 3}} component="fieldset" variant="standard">
                            <FormLabel component="legend">{translate('ra.changes.dialog.print_order')}</FormLabel>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox checked={printState.orderNum} onChange={handlePrintChange}
                                                       name="orderNum"/>}
                                    label={translate('ra.changes.dialog.fields.orderNum')}/>
                                <FormControlLabel control={<Checkbox checked={printState.client} onChange={handlePrintChange}
                                                                     name="client"/>}
                                                  label={translate('ra.changes.dialog.fields.client')}/>
                                <FormControlLabel control={<Checkbox checked={printState.location} onChange={handlePrintChange}
                                                                     name="location"/>}
                                                  label={translate('ra.changes.dialog.fields.location')}/>
                                <FormControlLabel control={<Checkbox checked={printState.product} onChange={handlePrintChange}
                                                                     name="product"/>}
                                                  label={translate('ra.changes.dialog.fields.product')}/>
                                <FormControlLabel control={<Checkbox checked={printState.numOfItems} onChange={handlePrintChange}
                                                                     name="numOfItems"/>}
                                                  label={translate('ra.changes.dialog.fields.numOfItems')}/>
                                <FormControlLabel control={<Checkbox checked={printState.duration} onChange={handlePrintChange}
                                                                     name="duration"/>}
                                                  label={translate('ra.changes.dialog.fields.duration')}/>
                                <FormControlLabel control={<Checkbox checked={printState.nextChange} onChange={handlePrintChange}
                                                                     name="nextChange"/>}
                                                  label={translate('ra.changes.dialog.fields.nextChange')}/>
                                <FormControlLabel control={<Checkbox checked={printState.note} onChange={handlePrintChange}
                                                                     name="note"/>}
                                                  label={translate('ra.changes.dialog.fields.note')}/>

                            </FormGroup>
                        </FormControl>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{translate('ra.changes.dialog.fields.close')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}