import {InputGuesser} from "@api-platform/admin";
import {ReferenceInput, SelectInput, useTranslate} from "react-admin";

// @ts-ignore
export const ProductInput = (props) => {
    const translate = useTranslate();
    return <>
        <InputGuesser source={"name"} label={translate('ra.product.name')}/>
        <InputGuesser source={"barcode"} label={translate('ra.product.barcode')}/>
        <ReferenceInput  source={"productType"} reference="product_types">
            <SelectInput optionText="name" label={translate('ra.product_type.short_name')}/>
        </ReferenceInput>
    </>
};