import {FieldGuesser, ListGuesser} from "@api-platform/admin";
import {useTranslate, Filter, TextInput, TextField, ReferenceField, SelectInput, ReferenceInput} from 'react-admin';

// @ts-ignore
const FilterBar = (props) => {
    const translate = useTranslate();
    return <Filter context={"button"} {...props}>
        <TextInput label={translate('ra.client_contact.name')} source='name'/>
        <ReferenceInput source={"client"} reference="clients" label={translate('ra.client.name')}>
            <SelectInput optionText="name" label={translate('ra.client.name')}/>
        </ReferenceInput>
    </Filter>
}

// @ts-ignore
export const ClientContactList = (props) => {
    const translate = useTranslate();
    return <>
        <ListGuesser bulkActionButtons={false} {...props} exporter={false} filters={<FilterBar/>}
                     title={'Kontakti klijenata'}>

            <FieldGuesser source={"name"} label={translate('ra.client_contact.name')}/>
            <FieldGuesser source={"title"} label={translate('ra.client_contact.title')}/>
            <FieldGuesser source={"phone"} label={translate('ra.client_contact.phone')}/>
            <FieldGuesser source={"email"} label={translate('ra.client_contact.email')}/>
            <ReferenceField source={"client"} reference="clients" link={"show"} label={translate('ra.client.name')}>
                <TextField source="name"/>
            </ReferenceField>


        </ListGuesser>

    </>
};