import {LocationFields} from "./locationFields";
import {LocationTitle} from "./locationTitle";
import {Edit, SimpleForm} from 'react-admin';
import {ScentToolbar} from "../layout/ScentToolbar";
import {DeleteLocation} from "./deleteLocation";


// @ts-ignore
export const LocationEdit = (props) => {
    const deleteButton = <DeleteLocation/>;
    return <>
        <Edit title={<LocationTitle/>}>
            <SimpleForm toolbar={<ScentToolbar deleteButton={deleteButton}/>}>
                <LocationFields {...props}/>
            </SimpleForm>
        </Edit>

    </>
};