import {Grid, Typography} from "@mui/material";
import * as React from "react";
import {useTranslate} from "react-admin";
import {ReportContainer} from "./reportContainer";

// @ts-ignore
const translateKey = (key) => {
    if (key === 'clientsInPeriod') {
        return 'ra.reports.type_clients.in_period';
    } else if (key === 'clientsPeriodStart') {
        return 'ra.reports.type_clients.start';
    } else if (key === 'clientsPeriodEnd') {
        return 'ra.reports.type_clients.end';
    }
    return key;
}
// @ts-ignore
export const ReportClients = (props) => {
    const translate = useTranslate();
    const [metrics, setMetrics] = React.useState([]);

    React.useEffect(() => {
        // @ts-ignore
        setMetrics(props.metrics);
    }, [props.metrics]);

    return <ReportContainer {...props}>

        <Typography typography={"h5"}
                    align={"center"}> {translate('ra.reports.type_clients.for_period')} {props.startDateHuman} - {props.endDateHuman}</Typography>
        <Grid container columns={12} sx={{marginTop: 2}}>
            <Grid item xs={6} className={"change-row-header"}>
                {translate('ra.reports.type_clients.item')}
            </Grid>
            <Grid item xs={6} className={"change-row-header"}>
                {translate('ra.reports.type_clients.value')}
            </Grid>

        </Grid>

        {// @ts-ignore
            metrics.map((row) => {
                // @ts-ignore
                const className = 'report-row';
                // @ts-ignore
                return <Grid container columns={12} key={//@ts-ignore
                    row.key}>

                    <Grid item xs={6} className={className}>
                        {// @ts-ignore
                            translate(translateKey(row.key))}
                    </Grid>
                    <Grid item xs={6} className={className}>
                        {// @ts-ignore
                            row.value}
                    </Grid>

                </Grid>

            })}

        {metrics.length === 0 &&
            <div>{translate('ra.reports.no_data')}</div>
        }

    </ReportContainer>
}