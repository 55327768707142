import {FieldGuesser} from "@api-platform/admin";
import {ShowGuesser} from "@api-platform/admin";
import {useTranslate} from "react-admin";
import {LocationTagTitle} from "./locationTagTitle";
// @ts-ignore
export const LocationTagShow = props => {
    const translate = useTranslate();
    return <>
        <ShowGuesser {...props} title={<LocationTagTitle/>}>
            <FieldGuesser source={"name"} label={translate('ra.location_tag.name')}/>

        </ShowGuesser>
    </>
};