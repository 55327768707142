import {FieldGuesser} from "@api-platform/admin";
import {ShowGuesser} from "@api-platform/admin";
import {useTranslate} from "react-admin";
import {DeviceColorTitle} from "./deviceColorTitle";


// @ts-ignore
export const DeviceColorShow = props => {
    const translate = useTranslate();
    return <>
        <ShowGuesser {...props} title={<DeviceColorTitle/>}>
            <FieldGuesser source={"name"} label={translate('ra.device_colors.name')}/>
        </ShowGuesser>
    </>
};