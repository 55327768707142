import * as React from "react";
import {
    RecordContextProvider,
    ReferenceArrayField,
    ReferenceField,
    SingleFieldList,
    TextField, useTranslate,
} from "react-admin";
import {Grid} from "@mui/material";

// @ts-ignore
export const ChangeListRowPrint = (props) => {
    const translate = useTranslate();

    return <RecordContextProvider value={props.item}>
        <>
            <Grid item xs={2} className={"change-row-border"}>
                <TextField source="client.name"/>

            </Grid>
            <Grid item xs={3} className={"change-row-border"}>

                <>
                    <TextField source="location.name"/> <br/>
                    <TextField source="location.address"/> - <TextField source="location.city"/> <br/>
                    ---
                    <br/>
                    <ReferenceArrayField source="location.clientContact" reference="client_contacts">
                        <SingleFieldList linkType={false} sx={{display: "block"}}>
                            <>
                                <TextField source="name"/> - <TextField source="phone"/>
                                <br/>
                            </>
                        </SingleFieldList>

                    </ReferenceArrayField>
                </>

            </Grid>
            <Grid item xs={2} className={"change-row-border"}>
                {props.item.productReplacement && <>
                    {translate('ra.product.original')}: {props.item.product.name} <br/>
                    {translate('ra.product.new')}: {props.item.productReplacement.name}
                </>}
                {!props.item.productReplacement && <TextField source="product.name"/>}

                <br/>

                <ReferenceField source="product.productType" reference="product_types" link={false}>
                    <TextField source="name"/>
                </ReferenceField>

            </Grid>
            <Grid item xs={1} className={"change-row-border"}>

                <TextField source="numOfItems"/>

            </Grid>
            <Grid item xs={1} className={"change-row-border"}>

                <TextField source="duration"/>

            </Grid>


            <Grid item xs={3} className={"change-row-border"}>

                <TextField source="note"/>


            </Grid>


        </>
    </RecordContextProvider>
}