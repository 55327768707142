import {Menu, useTranslate, usePermissions} from 'react-admin';
import EventNoteIcon from '@mui/icons-material/EventNote';
import StorageIcon from '@mui/icons-material/Storage';
import AssessmentIcon from '@mui/icons-material/Assessment';

export const ScentMenu = () => {
    const translate = useTranslate();
    const {permissions} = usePermissions();

    return <Menu>
        <Menu.DashboardItem/>
        <Menu.ResourceItem name="partners"/>
        <Menu.ResourceItem name="users"/>
        <Menu.ResourceItem name="product_types"/>
        <Menu.ResourceItem name="products"/>
        <Menu.ResourceItem name="clients"/>
        <Menu.ResourceItem name="client_contacts"/>
        <Menu.ResourceItem name="locations"/>
        <Menu.ResourceItem name="device_products"/>
        <Menu.ResourceItem name="device_colors"/>
        <Menu.ResourceItem name="location_tags"/>
        {permissions !== 'admin' ?
            <div>
                <Menu.Item to="/changes/work" primaryText={translate('ra.changes.label')} leftIcon={<EventNoteIcon/>}/>
                <Menu.Item to="/changes/loading" primaryText={translate('ra.loading_order.label')}
                           leftIcon={<StorageIcon/>}/>

            </div>
            :
            null }
        <Menu.Item to="/reports" primaryText={translate('ra.reports.label')} leftIcon={<AssessmentIcon/>}/>

    </Menu>
};