import * as React from "react";
import {
    RecordContextProvider,
    ReferenceArrayField,
    ReferenceField,
    SingleFieldList,
    TextField,
    useTranslate
} from "react-admin";
import {Grid, MenuItem, Select, TextField as FormTextField} from "@mui/material";
import {ProductInput} from "./ProductInput";
import {useWatch} from "react-hook-form";
import {ErrorMessage} from "@hookform/error-message"
import {CurrentProductType} from "./currentProductType";
import {padDate} from "../utils/dateUtil";

import {ACTIVE_STATUS, CHANGED_STATUS, DELAYED_STATUS, DELETED_STATUS, STATUS_MAPPING} from "../config/contant";
import {useEffect} from "react";
import {validationErrorCallback} from "./validationError";


// @ts-ignore
export const ChangeListRow = (props) => {
    const [currentProductTypeValue, setCurrentProductTypeValue] = React.useState(null);

    const translate = useTranslate();
    const currentI = parseInt(props.item.id.split("/").pop());
    const currentStatus = useWatch({name: currentI + '.status', control: props.control});
    const currentDuration = useWatch({name: currentI + '.duration', control: props.control});
    let endAtDate = new Date(props.item.endAt);
    let initialEndDate= new Date(props.item.endAt);
    endAtDate.setDate(endAtDate.getDate() + (currentDuration !== undefined ? parseInt(currentDuration) : parseInt(props.item.duration)));
    const endDateFormated = endAtDate.getFullYear() + "-" + padDate(endAtDate.getMonth() + 1) + "-" + padDate(endAtDate.getDate());
    if (currentStatus === DELAYED_STATUS || currentStatus === DELETED_STATUS) {
        if (props.getValues(currentI + '.numOfItems') !== props.item.numOfItems) {
            props.formSetValue(currentI + '.numOfItems', props.item.numOfItems);
        }
        if (props.getValues(currentI + '.duration') !== props.item.duration) {
            props.formSetValue(currentI + '.duration', props.item.duration);
        }
        if (props.getValues(currentI + '.orderNum') !== props.item.orderNum) {
            props.formSetValue(currentI + '.orderNum', props.item.orderNum);
        }
        if (props.getValues(currentI + '.product') !== props.item.product.id) {
            props.formSetValue(currentI + '.product', props.item.product['@id']);
        }
    }

    useEffect(()=>{
        props.formSetValue(currentI + '.endAt', endDateFormated);
    },[endDateFormated]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.item.status === ACTIVE_STATUS) {
            props.register(currentI + '.product', {required: translate("ra.changes.fields.product.required")});
            props.formSetValue(currentI + '.id', currentI);
            props.formSetValue(currentI + '.endAt', endDateFormated);
            const product = props.item.productReplacement ? props.item.productReplacement : props.item.product;
            props.formSetValue(currentI + '.product', product['@id']);

            if (currentStatus === undefined) {
                props.formSetValue(currentI + '.status', ACTIVE_STATUS);
            }

            if (props.getValues(currentI + '.orderNum') === undefined) {
                props.formSetValue(currentI + '.orderNum', props.item.orderNum);
            }
            if (props.getValues(currentI + '.numOfItems') === undefined) {
                props.formSetValue(currentI + '.numOfItems', props.item.numOfItems);
            }
            if (props.getValues(currentI + '.duration') === undefined) {
                props.formSetValue(currentI + '.duration', props.item.duration);
            }
            if (props.getValues(currentI + '.note') === undefined) {
                props.formSetValue(currentI + '.note', props.item.note);
            }

        }

        return () => {
            props.unregister(currentI + '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // @ts-ignore
    const handleProductChange = (newValue) => {
        if (newValue === null) {
            props.formSetValue(currentI + '.product', newValue);
            setCurrentProductTypeValue(null);
        } else {
            props.formSetValue(currentI + '.product', newValue['@id']);
            setCurrentProductTypeValue(newValue.productType);
        }
    }


    return <RecordContextProvider value={props.item}>
        <>
            {props.uiColumns.orderNum && <Grid item xs={1} className={"change-row-border"}>
                {props.item.status === ACTIVE_STATUS ?
                    <><FormTextField defaultValue={props.item.orderNum} variant="outlined"
                                     sx={{width: 50}} {...props.register(currentI + '.orderNum', {
                        min: {
                            value: 1,
                            message: translate("ra.changes.fields.orderNum.min")
                        },
                        pattern: {
                            value: /[0-9]/,
                            message: translate("ra.changes.fields.orderNum.number")
                        }
                    })}/>
                        <ErrorMessage errors={props.formErrors} name={currentI + '.orderNum'}
                                      render={validationErrorCallback}/>
                    </>
                    : <TextField source="orderNum"/>}
            </Grid>
            }
            {props.uiColumns.client && <Grid item xs={1} className={"change-row-border"}>
                <TextField source="client.name"/>

            </Grid>
            }
            {props.uiColumns.location && <Grid item xs={2} className={"change-row-border"}>

                <>
                    <TextField source="location.name"/> <br/>
                    <TextField source="location.address"/> - <TextField source="location.city"/> <br/>
                    ---
                    <br/>
                    <ReferenceArrayField source="location.clientContact" reference="client_contacts">
                        <SingleFieldList linkType={false} sx={{display: "block"}}>
                            <>
                                <TextField source="name"/> - <TextField source="phone"/>
                                <br/>
                            </>
                        </SingleFieldList>

                    </ReferenceArrayField>
                </>

            </Grid>
            }
            {props.uiColumns.product && <Grid item xs={2} className={"change-row-border"}>
                {props.item.status === ACTIVE_STATUS && currentStatus !== DELETED_STATUS && currentStatus !== DELAYED_STATUS ?
                    <>
                        <ProductInput item={props.item} hanleProductChange={handleProductChange}/>
                        <ErrorMessage errors={props.formErrors} name={currentI + '.product'}
                                      render={validationErrorCallback}/>
                    </> :

                    <TextField source="product.name"/>
                }

                <br/>
                {currentProductTypeValue !== null ?
                    <CurrentProductType productType={currentProductTypeValue}/>
                    :

                    <ReferenceField source="product.productType" reference="product_types" link={false}>
                        <TextField source="name"/>
                    </ReferenceField>

                }
            </Grid>
            }
            {props.uiColumns.numOfItems &&
                <Grid item xs={1} className={"change-row-border"}>
                    {props.item.status === ACTIVE_STATUS ?
                        <>
                            <FormTextField variant="outlined"
                                           {...props.register(currentI + '.numOfItems', {
                                               min: {
                                                   value: 1,
                                                   message: translate("ra.changes.fields.numOfItems.min")
                                               },
                                               pattern: {
                                                   value: /[0-9]/,
                                                   message: translate("ra.changes.fields.numOfItems.number")
                                               }
                                           })
                            }
                                           defaultValue={props.item.numOfItems}
                                           disabled={currentStatus === ACTIVE_STATUS || currentStatus === DELAYED_STATUS || currentStatus === DELETED_STATUS}/>
                            <ErrorMessage errors={props.formErrors} name={currentI + '.numOfItems'}
                                          render={validationErrorCallback}/>
                        </>
                        :
                        <TextField source="numOfItems"/>
                    }
                </Grid>
            }
            {props.uiColumns.duration && <Grid item xs={1} className={"change-row-border"}>
                {props.item.status === ACTIVE_STATUS ?
                    <>
                        <FormTextField variant="outlined" {...props.register(currentI + '.duration', {
                        min: {
                            value: 1,
                            message: translate("ra.changes.fields.duration.min")
                        },
                        pattern: {
                            value: /[0-9]/,
                            message: translate("ra.changes.fields.duration.number")
                        }
                    })}
                                     defaultValue={props.item.duration}
                                     disabled={currentStatus === ACTIVE_STATUS || currentStatus === DELAYED_STATUS || currentStatus === DELETED_STATUS}/>
                        <ErrorMessage errors={props.formErrors} name={currentI + '.duration'}
                                      render={validationErrorCallback}/>
                    </> :
                    <TextField source="duration"/>
                }
            </Grid>
            }

            {props.uiColumns.nextChange && <Grid item xs={2} className={"change-row-border"}>
                {props.item.status === ACTIVE_STATUS ?
                    <>
                        <FormTextField type="date" variant="outlined" {...props.register(currentI + '.endAt',
                            {
                                required: translate("ra.changes.fields.endAt.required"),
                                pattern: {
                                    value: /[0-9]{4}-[0-9]{2}-[0-9]{2}/,
                                    message: translate("ra.changes.fields.endAt.format")
                                },
                                // @ts-ignore
                                validate: v => {
                                    if ( new Date(v) >= initialEndDate ) return true;
                                    return translate("ra.changes.fields.endAt.future")
                                }

                            })}
                                       defaultValue={endDateFormated}
                                       disabled={currentStatus === ACTIVE_STATUS || currentStatus === CHANGED_STATUS || currentStatus === DELETED_STATUS}/>
                        <ErrorMessage errors={props.formErrors} name={currentI + '.endAt'}
                                      render={validationErrorCallback}/>
                    </>
                    : props.item.status === DELETED_STATUS ? <span>-</span> :
                        <FormTextField type="date" variant="outlined" disabled
                                       defaultValue={endDateFormated}/>
                }
            </Grid>
            }
            {props.uiColumns.note && <Grid item xs={3} className={"change-row-border"}>
                {props.item.status === ACTIVE_STATUS ?
                    <FormTextField fullWidth variant="outlined" {...props.register(currentI + '.note')}
                                   defaultValue={props.item.note} multiline={true}/> :
                    <TextField source="note"/>
                }

            </Grid>
            }

            <Grid item xs={1} className={"change-row-border"}>
                {props.item.status === ACTIVE_STATUS ?
                    <Select
                        defaultValue={props.item.status}
                        {...props.register(currentI + '.status')}>
                        <MenuItem value="/statuses/1">{translate('ra.statuses.active')}</MenuItem>
                        <MenuItem value="/statuses/2">{translate('ra.statuses.changed')}</MenuItem>
                        <MenuItem value="/statuses/3">{translate('ra.statuses.delayed')}</MenuItem>
                        <MenuItem value="/statuses/4">{translate('ra.statuses.deleted')}</MenuItem>
                    </Select>
                    :

                    <span> {// @ts-ignore
                        translate(STATUS_MAPPING[props.item.status])
                    }</span>

                }

            </Grid>
        </>
    </RecordContextProvider>
}