import * as React from "react";
import {useListController, ListContextProvider, useTranslate} from 'react-admin';
import {ChangesListViewPrint} from "./changeListViewPrint";
import {useSearchParams} from 'react-router-dom';
import {padDate} from "../utils/dateUtil";
import {SortDirection, Typography} from "@mui/material";


// @ts-ignore
export const ChangeListPrint = () => {
        const translate = useTranslate();
        const [searchParams] = useSearchParams();

        let date = searchParams.get('date');
        let startDate = date !== null ? new Date(date) : new Date();
        let endDate = new Date(startDate.toString());
        endDate.setDate(startDate.getDate() + 1);
        const startDateFormatted = startDate.getFullYear() + "-" + padDate(startDate.getMonth() + 1) + "-" + padDate(startDate.getDate());
        const endDateFormatted = endDate.getFullYear() + "-" + padDate(endDate.getMonth() + 1) + "-" + padDate(endDate.getDate());
        let sort_direction = searchParams.get('sort_direction');
        let sort_field = searchParams.get('sort_field');
        if (sort_field === null || sort_field === 'null') {
            sort_field = '';
        }
        if (sort_direction === null || sort_direction === 'null') {
            sort_direction = '';
        }


        const changes = useListController({
            resource: 'changes',
            perPage: 1000,
            filter: {
                'endAt[after]': startDateFormatted,
                'endAt[strictly_before]': endDateFormatted,
                'status': '/statuses/1',
            },
            // @ts-ignore
            sort: {'field': '' + sort_field, 'order': sort_direction}

        });

        const changesFrom = padDate(startDate.getDate()) + "." + padDate(startDate.getMonth() + 1) + "." + startDate.getFullYear();

    return <>
            <div className={"print-background"}>
                <Typography variant={"h5"}
                            sx={{textAlign: "center"}}>{translate('ra.changes.print.print_title')} {changesFrom}</Typography>

                <ListContextProvider value={changes}>
                    <ChangesListViewPrint/>
                </ListContextProvider>

            </div>
        </>
    }
;
