import {DeviceColorInputFields} from "./deviceColorInputFields";
import {DeviceColorTitle} from "./deviceColorTitle";
import {Edit, SimpleForm} from "react-admin";
import {ScentToolbar} from "../layout/ScentToolbar";
import {DeleteColorType} from "./deleteColorType";


// @ts-ignore
export const DeviceColorEdit = (props) => {
    const deleteButton = <DeleteColorType/>;
    return <>
        <Edit title={<DeviceColorTitle/>}>
            <SimpleForm toolbar={<ScentToolbar deleteButton={deleteButton}/>}>
                <DeviceColorInputFields {...props}/>
            </SimpleForm>
        </Edit>

    </>
};