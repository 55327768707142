import {CreateGuesser, InputGuesser } from "@api-platform/admin";
import {ReferenceInput, useTranslate, AutocompleteInput, TextInput, DateInput, useGetOne, Loading} from "react-admin";
import {useSearchParams} from "react-router-dom";
import {padDate} from "../utils/dateUtil";


// @ts-ignore
export const ChangeCreate = (props) => {
    const translate = useTranslate();
    const [searchParams] = useSearchParams();
    let defaultLocation = searchParams.get('location');
    let startDate = new Date();
    const { data, isLoading, error }= useGetOne('locations', {id: defaultLocation});
    if (isLoading || error) {
        return <Loading/>;
    }

    const startDateFormatted = startDate.getFullYear() + "-" + padDate(startDate.getMonth() + 1) + "-" + padDate(startDate.getDate());

    // @ts-ignore
    const redirectLocation = "/locations/" + encodeURIComponent(defaultLocation !== null ?defaultLocation: "") +"/show"

    return <CreateGuesser {...props} redirect={redirectLocation} >
        <DateInput  source={"startAt"} label={translate('ra.changes.startAt')} type={"date"} defaultValue={startDateFormatted}/>
        <InputGuesser source={"duration"} label={translate('ra.changes.duration')}/>
        <InputGuesser source={"numOfItems"} label={translate('ra.changes.numberOfItems')}/>
        <ReferenceInput  source={"product"} reference="products">
            <AutocompleteInput optionText="name" label={translate('ra.product.name')}/>
        </ReferenceInput>

        <ReferenceInput  source={"client"} reference="clients">
            <AutocompleteInput  optionText={"name"} label={translate('ra.client.name')} defaultValue={data.client} disabled/>
        </ReferenceInput>

        <ReferenceInput  source={"location"} reference="locations">
            <AutocompleteInput  optionText={"name"} label={translate('ra.location.name')} defaultValue={data.id} disabled/>
        </ReferenceInput>

        <TextInput  source={"note"} label={translate('ra.changes.note')} multiline={true}/>
    </CreateGuesser>
};