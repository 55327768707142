import {FieldGuesser} from "@api-platform/admin";
import {ShowGuesser} from "@api-platform/admin";
import {useTranslate, UrlField, ReferenceArrayField, Datagrid, TextField, ShowButton} from "react-admin";
import {PartnerTitle} from "./partnerTitle";


// @ts-ignore
export const PartnerShow = props => {
    const translate = useTranslate();
    return <>
        <ShowGuesser {...props} title={<PartnerTitle/>}>
            <FieldGuesser source={"name"} label={translate('ra.company.name')}/>
            <FieldGuesser source={"address"} label={translate('ra.company.address')}/>
            <FieldGuesser source={"city"} label={translate('ra.company.city')}/>
            <FieldGuesser source={"poostCode"} label={translate('ra.company.postCode')}/>
            <FieldGuesser source={"pib"} label={translate('ra.company.pib')}/>
            <FieldGuesser source={"registrationNumber"} label={translate('ra.company.registrationNumber')}/>
            <FieldGuesser source={"phone"} label={translate('ra.company.phone')}/>
            <FieldGuesser source={"email"} label={translate('ra.company.email')}/>
            <UrlField source={"site"} label={translate('ra.company.site')}/>
            <FieldGuesser source={"workHours"} label={translate('ra.company.workHours')}/>
            <h3>Korisnici</h3>
            <ReferenceArrayField source={"user"} reference={"users"} label={false}>
                <Datagrid bulkActionButtons={false}>
                    <TextField source="name" label={translate('ra.user.name')}/>
                    <TextField source="username" label={translate('ra.user.username')}/>
                    <TextField source="email" label={translate('ra.user.email')}/>

                    <ShowButton />
                    {/*<DeleteButton mutationMode={"optimistic"}/>*/}
                </Datagrid>
            </ReferenceArrayField >
        </ShowGuesser>
    </>
};