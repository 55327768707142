import * as React from "react";
import {Title, useTranslate, useDataProvider, useNotify, Loading} from 'react-admin';

import {useSearchParams} from 'react-router-dom';
import {padDate} from "../utils/dateUtil";
import {useEffect} from "react";
import {ReportFilters} from "./reportFilters";
import {ReportChanges} from "./reportChanges";
import {ReportClients} from "./reportClients";
import {ReportDevices} from "./reportDevices";
import {ReportProducts} from "./reportProducts";
import {ReportProductTypes} from "./reportProductTypes";
import {ReportPartner} from "./reportPartner";


// @ts-ignore
export const ReportMain = (props) => {
        const translate = useTranslate();
        const dataProvider = useDataProvider();
        const notify = useNotify();

        const [searchParams] = useSearchParams();
        const [metrics, setMetrics] = React.useState([]);
        const [loading, setLoading] = React.useState(false);


        let startDateString = searchParams.get('startDate');
        let endDateString = searchParams.get('endDate');
        let reportType = searchParams.get('report_type');
        let startDate = startDateString !== null ? new Date(startDateString) : new Date(new Date().setDate(1));
        let endDate = endDateString !== null ? new Date(endDateString) : new Date(new Date().setDate(31));


        const startDateFormatted = startDate.getFullYear() + "-" + padDate(startDate.getMonth() + 1) + "-" + padDate(startDate.getDate());
        const endDateFormatted = endDate.getFullYear() + "-" + padDate(endDate.getMonth() + 1) + "-" + padDate(endDate.getDate());

        const startDateHuman = padDate(startDate.getDate()) + "." + padDate(startDate.getMonth() + 1) + "." + startDate.getFullYear();
        const endDateHuman = padDate(endDate.getDate()) + "." + padDate(endDate.getMonth() + 1) + "." + endDate.getFullYear();

        useEffect(() => {
            setLoading(true);
            dataProvider.getReport(startDateFormatted, endDateFormatted, reportType)
                // @ts-ignore
                .then(response => {
                    // @ts-ignore
                    setMetrics(response.json['hydra:member']);

                    setLoading(false);
                })
                // @ts-ignore
                .catch(error => {
                    notify(`ra.notification.http_error`, {type: 'error'});
                    setLoading(false);
                });
        }, [startDateFormatted, endDateFormatted, reportType, dataProvider, notify]);

        if (loading) {
            return <Loading/>
        }
        const common = {
            startDateFormatted: startDateFormatted,
            endDateFormatted: endDateFormatted,
            startDateHuman: startDateHuman,
            endDateHuman: endDateHuman,
            reportType: reportType,
            metrics: metrics,
            print: props.print
        };

        return <>

            {props.print !== true &&
                <ReportFilters startDateFormatted={startDateFormatted} endDateFormatted={endDateFormatted}
                               report_type={reportType}/>}
            <Title title={translate('ra.reports.label')}/>
            {reportType === 'changes' &&
                <ReportChanges {...common}/>}
            {reportType === 'clients' &&
                <ReportClients {...common}/>}
            {reportType === 'devices' &&
                <ReportDevices {...common}/>}
            {reportType === 'products' &&
                <ReportProducts {...common}/>}
            {reportType === 'product_types' &&
                <ReportProductTypes {...common}/>}
            {reportType === 'partners' &&
                <ReportPartner {...common}/>}


        </>
    }
;
