import {PartnersEditFields} from "./partnerInputFields";
import {PartnerTitle} from "./partnerTitle";
import {Edit, SimpleForm} from "react-admin";
import {ScentToolbar} from "../layout/ScentToolbar";
import {DeletePartner} from "./deletePartner";


// @ts-ignore
export const PartnerEdit = (props) => {
    const deleteButton = <DeletePartner/>;
    return <>
        <Edit title={<PartnerTitle/>}>
            <SimpleForm toolbar={<ScentToolbar deleteButton={deleteButton}/>}>
                <PartnersEditFields {...props}/>
            </SimpleForm>
        </Edit>

    </>
};