import {FieldGuesser, ListGuesser} from "@api-platform/admin";
import {
    useTranslate,
    Filter,
    TextInput,
    ReferenceField,
    TextField,
    ReferenceInput,
    SelectInput,
    Button, useDataProvider, useNotify, useRedirect,
} from 'react-admin';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
// @ts-ignore
const FilterBar = (props) => {
    const translate = useTranslate();
    return <Filter context={"button"} {...props}>
        <TextInput label={translate('ra.user.email')} source='email'/>
        <TextInput label={translate('ra.user.name')} source='name'/>
        <TextInput label={translate('ra.user.username')} source='username'/>
        <ReferenceInput source={"partner"} reference="partners" label={translate('ra.company.name')}>
            <SelectInput optionText="name" label={translate('ra.company.name')}/>
        </ReferenceInput>
    </Filter>
}

// @ts-ignore
export const UserList = props => {
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const redirect = useRedirect();

    const handleClick = (id: number): any => {

        dataProvider.getUserToken(id)
            // @ts-ignore
            .then(response => {
                console.log(response.json.token);
                localStorage.setItem('token', response.json.token);
                redirect('/');
                window.location.reload();
            })
            // @ts-ignore
            .catch(error => {
                notify(`ra.notification.http_error`, {type: 'error'});

            });
    }
    return <>
        <ListGuesser {...props} bulkActionButtons={false} {...props} exporter={false} filters={<FilterBar/>}
                     title={"Lista korisnika"}>
            <FieldGuesser source={"username"} label={translate('ra.user.username')}/>
            <FieldGuesser source={"email"} label={translate('ra.user.email')}/>
            <FieldGuesser source={"name"} label={translate('ra.user.name')}/>
            <ReferenceField source={"partner"} reference="partners" link={"show"} label={translate('ra.company.name')}>
                <TextField source="name"/>
            </ReferenceField>
            <Button label={translate('ra.user.login_as')} onClick={() => { handleClick(2) }} startIcon={<SwitchAccountIcon />}/>;
        </ListGuesser>
    </>
};