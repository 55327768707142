import {FieldGuesser, ListGuesser} from "@api-platform/admin";
import {useTranslate, Filter, TextInput, ReferenceField, TextField, SelectInput, ReferenceInput, BooleanInput} from 'react-admin';

//  @ts-ignore
const FilterBar= (props) => {
    const translate = useTranslate();
    return <Filter context={"button"} {...props}>
        <TextInput label={translate('ra.product.name')} source='name'/>
        <ReferenceInput source={"productType"} reference="product_types" label={translate('ra.product_type.short_name')}>
            <SelectInput optionText="name" label={translate('ra.product_type.short_name')}/>
        </ReferenceInput>

    </Filter>
}

// @ts-ignore
export const ProductList = props => {
    const translate = useTranslate();

    return <>
        <ListGuesser {...props} bulkActionButtons={false} {...props} exporter={false} filters={<FilterBar/>} title={"Lista proizvoda"}>
            <FieldGuesser source={"name"} label={translate('ra.product.name')}/>
            <FieldGuesser source={"barcode"} label={translate('ra.product.barcode')}/>
            <ReferenceField source={"productType"} reference="product_types" link={false} label={translate('ra.product_type.short_name')}>
                <TextField source="name"/>
            </ReferenceField>
        </ListGuesser>
    </>
};