import {
    Confirm,
    useDataProvider,
    useNotify,
    useRecordContext,
    useRedirect,
    useTranslate
} from "react-admin";
import DeleteIcon from "@mui/icons-material/Delete";
import * as React from "react";
import {StyledButton} from "../components/styledButton";
import clsx from "clsx";


// @ts-ignore
export const DeletePartner = (props) => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const translate = useTranslate()
    const notify = useNotify();
    const redirect = useRedirect();

    const [open, setOpen] = React.useState(false);

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleDelete = () => {
        dataProvider.delete(
            'partners',
            {id: record?.id}
        )
            // @ts-ignore
            .then(response => {
                notify(`ra.notification.deleted`, {type: 'success'});
                // @ts-ignore
                redirect('/partners');
            })
            // @ts-ignore
            .catch(error => {
                if (error.status === 422) {
                    notify(`ra.company.error_422`, {type: 'error'});
                } else {
                    notify(`ra.notification.http_error`, {type: 'error'});
                }
                setOpen(false);
            });
    };

    const isLoading = false;

    // @ts-ignore
    return <>
        <StyledButton
            onClick={handleClick}
            label={translate('ra.action.delete')}
            key="button"
            className={clsx('ra-delete-button')}>
            <DeleteIcon/>
        </StyledButton>
        <Confirm
            isOpen={open}
            loading={isLoading}
            title={translate('ra.message.delete_title')}
            content={translate('ra.message.delete_content')}

            onConfirm={handleDelete}
            onClose={handleDialogClose}
        />

    </>

}