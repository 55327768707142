export const ACTIVE_STATUS = "/statuses/1";
export const CHANGED_STATUS = "/statuses/2";
export const DELAYED_STATUS = "/statuses/3";
export const DELETED_STATUS = "/statuses/4";


export const STATUS_MAPPING = {
    "/statuses/1": 'ra.statuses.active',
    "/statuses/2": 'ra.statuses.changed',
    "/statuses/3": 'ra.statuses.delayed',
    "/statuses/4": 'ra.statuses.deleted',
}