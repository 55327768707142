import {dataProvider} from "../providers/dataProvider";

// @ts-ignore
export const handleChanges = (changes, notify, refresh, unregister, setSubmittedData, setSendingData) => {
    // @ts-ignore
    let data = [];
    let updated = {};
    // @ts-ignore
    Object.keys(changes).forEach(function (key) {
        const change = changes[key];

        if (change.id === undefined) {
            return;
        }

        // @ts-ignore
        updated[change.id] = true;
        let endAt = null;
        if (change.endAt) {
            endAt = new Date(change.endAt);
            endAt.setHours(7);
            endAt.setMinutes(0);
            endAt.setSeconds(0);
            endAt.setMilliseconds(0);
        }

        data.push({
            id: change.id,
            duration: parseInt(change.duration),
            endAt: endAt,
            status: change.status,
            note: change.note,
            product: change.product,
            numOfItems: parseInt(change.numOfItems),
            orderNum: parseInt(change.orderNum) || 1
        })
    });
    if (data.length === 0) {
        notify(`ra.changes.warning`, {type: 'warning'});
        setSendingData(false);
        return;
    }
    // @ts-ignore
    dataProvider.bulkSubmitChanges(data)
        .then(response => {
            notify(`ra.changes.success`, {type: 'success'});
            setSendingData(false);
            setSubmittedData(Object.assign({}, updated));
            // @ts-ignore
            data.forEach(function (change) {
                unregister(change.id + '');
            });
            refresh();
        })
        .catch(error => {
            if (error.response && error.response.status >= 500) {
                notify(`ra.changes.error_500`, {type: 'error'});
            } else if (error.status && error.status >= 400 && error.status < 500) {
                notify(`ra.changes.error_400`, {type: 'error'});
            }
            setSendingData(false);
        });


};