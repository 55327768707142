import {AppBar, AppBarClasses,  useAuthProvider} from 'react-admin';
import {Box, Typography} from '@mui/material';


//@ts-ignore
export const ScentAppBar = ({userMenu, props}) => {
    const authProvider = useAuthProvider();
    return <AppBar userMenu={userMenu ?? !!authProvider} {...props} className={"app-bar-print"}>
        <Typography
            variant="h6"
            color="inherit"
            className={AppBarClasses.title}
            id="react-admin-title"

        />

        <Box component="span" sx={{ flex: 1, alignItems: "center", display: "flex", justifyContent: "center"}} >
            <img src={"logo21.png"} style={{height: 64}}/>
        </Box>
    </AppBar>

}


