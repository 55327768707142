import {EditGuesser} from "@api-platform/admin";
import {ClientContactTitle} from "./clientContactTitle";
import {ClientContactFields} from "./clientContactFields";


// @ts-ignore
export const ClientContactEdit = (props) => {
    return <>
        <EditGuesser title={<ClientContactTitle/>} {...props} >
            <ClientContactFields {...props}/>
        </EditGuesser>
    </>
};