import {FieldGuesser, ListGuesser} from "@api-platform/admin";
import {useTranslate, Filter, TextInput} from 'react-admin';

// @ts-ignore
const FilterBar = (props) => {
    const translate = useTranslate();
    return <Filter context={"button"} {...props}>
        <TextInput label={translate('ra.client.name')} source='name'/>
        <TextInput label={translate('ra.client.city')} source='city'/>
    </Filter>
}

// @ts-ignore
export const ClientList = (props) => {
    const translate = useTranslate();
    return <>
        <ListGuesser bulkActionButtons={false} {...props} exporter={false} filters={<FilterBar/>}
                     title={'Klijenti'}>
            <FieldGuesser source={"name"} label={translate('ra.client.name')}/>
            <FieldGuesser source={"city"} label={translate('ra.client.city')}/>
            <FieldGuesser source={"phone"} label={translate('ra.client.phone')}/>
            <FieldGuesser source={"email"}/>
            <FieldGuesser source={"workingHours"} label={translate('ra.client.workHours')}/>
        </ListGuesser>

    </>
};