import {EditGuesser} from "@api-platform/admin";
import {UserInput} from "./userInput";
import {UserTitle} from "./userTitle";

// @ts-ignore
export const UserEdit = props => {
    return <>
        <EditGuesser {...props} title={<UserTitle/>}>
            <UserInput {...props}/>
        </EditGuesser>
    </>
};