import {alpha, styled} from "@mui/material/styles";
import {Button} from "react-admin";

export const StyledButton = styled(Button, {
    overridesResolver: (props, styles) => styles.root,
})(({theme}) => ({
    color: theme.palette.error.main,
    '&:hover': {
        backgroundColor: alpha(theme.palette.error.main, 0.12),
        // Reset on mouse devices
        '@media (hover: none)': {
            backgroundColor: 'transparent',
        },
    },
}));