import {ProductTypeInputFields} from "./productTypeInputFields";
import {ProductTypeTitle} from "./productTypeTitle";
import {Edit, SimpleForm} from "react-admin";
import {ScentToolbar} from "../layout/ScentToolbar";
import {DeleteProductType} from "./deleteProductType";


// @ts-ignore
export const ProductTypeEdit = (props) => {
    const deleteButton = <DeleteProductType/>;
    return <>
        <Edit title={<ProductTypeTitle/>}>
            <SimpleForm toolbar={<ScentToolbar deleteButton={deleteButton}/>}>
                <ProductTypeInputFields {...props}/>
            </SimpleForm>
        </Edit>

    </>
};