import {Button, Card, CardContent} from "@mui/material";
import {
    Datagrid,
    DeleteButton, FunctionField,
    ReferenceArrayField,
    ReferenceField,
    TextField,
    useRecordContext,
    useTranslate
} from "react-admin";
import * as React from "react";
import AddIcon from "@mui/icons-material/Add";
import {padDate} from "../utils/dateUtil";
import {FieldGuesser} from "@api-platform/admin";

// @ts-ignore
const RecordAwareAddDevice = (props) => {
    const record = useRecordContext();
    if (!record) return null;

    return <>
        <div className='align-right'>
            <Button href={"#/devices/create?location=" + record.id} startIcon={<AddIcon/>}>
                Dodaj uređaj</Button>
        </div>
    </>;
}
// @ts-ignore
const RecordAwareDeviceDelete = (props) => {
    const record = useRecordContext();
    return <>
        <DeleteButton mutationMode={"pessimistic"}
                      redirect={"/locations/" + encodeURIComponent(record?.location) + "/show"}/>
    </>;

}
export const LocationDevices = () => {
    const translate = useTranslate();
    // @ts-ignore
    const renderDate = (record) => {
        let date = new Date(record.startAt);
        return padDate(date.getDate()) + ". " + (date.toLocaleString('sr-Latn-RS', {month: 'short'})) + ". " + date.getFullYear();

    }
    return <>
        <h3>Uređaji</h3>
        <Card>
            <CardContent>
                <RecordAwareAddDevice/>
                <ReferenceArrayField source={"devices"} reference={"devices"} label={false}>
                    <Datagrid bulkActionButtons={false}>
                        <FieldGuesser source="vid" label={translate('ra.device.id')}/>
                        <ReferenceField source={"deviceProduct"} reference="device_products"
                                        label={translate('ra.device_product.name')} link={false} fullWidth>
                            <TextField source="name" label={translate('ra.device_product.name')} fullWidth/>
                        </ReferenceField>
                        <ReferenceField source={"color"} reference="device_colors"
                                        label={translate('ra.device_colors.name')} link={false} fullWidth>
                            <TextField source="name" label={translate('ra.device_colors.name')} fullWidth/>
                        </ReferenceField>
                        <FunctionField render={renderDate} label={translate('ra.device.montage_date')} sortable={false}/>
                        <FieldGuesser source={"loaned"} label={translate('ra.device.loaned')}/>
                        <FieldGuesser source="note" label={translate('ra.device.note')}/>
                        <div style={{textAlign: "right"}}>
                            <RecordAwareDeviceDelete/>
                        </div>
                    </Datagrid>
                </ReferenceArrayField>
            </CardContent>
        </Card>
    </>
}