import {FieldGuesser, ListGuesser} from "@api-platform/admin";
import {
    useTranslate,
    Filter,
    TextInput,
    ReferenceField,
    TextField,
    SelectInput,
    ReferenceInput,
    AutocompleteInput
} from 'react-admin';


//  @ts-ignore
const FilterBar= (props) => {
    const translate = useTranslate();
    return <Filter context={"button"} {...props}>
        <TextInput label={translate('ra.location.name')} source='name'/>
        <TextInput label={translate('ra.location.city')} source='city'/>
        <ReferenceInput source={"client"} reference="clients" label={translate('ra.client.name')}>
            <SelectInput optionText="name" label={translate('ra.client.name')}/>
        </ReferenceInput>
        <ReferenceInput source={"tags"} reference="location_tags" label={translate('ra.location_tag.name')}>
            <AutocompleteInput optionText="name" label={translate('ra.location_tag.name')} />
        </ReferenceInput>

    </Filter>
}

// @ts-ignore
export const LocationList = props => {
    const translate = useTranslate();

    return <>
        <ListGuesser {...props} bulkActionButtons={false} {...props} exporter={false} filters={<FilterBar/>} title={"Lista lokacija"}>
            <FieldGuesser source={"name"} label={translate('ra.location.name')}/>
            <FieldGuesser source={"address"} label={translate('ra.location.address')}/>
            <FieldGuesser source={"city"} label={translate('ra.location.city')} />
            <FieldGuesser source={"postCode"} label={translate('ra.location.postCode')}/>
            <FieldGuesser source={"workingHours"} label={translate('ra.location.workingHours')}/>

            <ReferenceField source={"client"} reference="clients" link={"show"} label={translate('ra.client.name')}>
                <TextField source="name"/>
            </ReferenceField>
        </ListGuesser>
    </>
};