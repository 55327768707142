import {   useRecordContext} from "react-admin";
import {Button, Card, CardContent} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {LocationChanges} from "./locationChanges";

// @ts-ignore
 export const LocationChangesRecord = (props) => {
    const record = useRecordContext();
    if (!record) return null;
    return <>
        <h3>Proizvodi</h3>
        <Card>
            <CardContent>
                <div className='align-right'>
                    <Button href={"#/changes/create?location=" + record.id} startIcon={<AddIcon/>}>Dodaj
                        proizvod</Button>
                </div>
                <LocationChanges location={record}/>
            </CardContent>
        </Card>
    </>;
}