import {useTranslate} from "react-admin";
import {Grid} from "@mui/material";
import * as React from "react";

export const ChangeListViewHeaderPrint = () => {
    const translate = useTranslate();
    return <>
        <Grid item xs={2} className={"change-row-header"}>
            {translate('ra.client.name')}
        </Grid>
        <Grid item xs={3} className={"change-row-header"}>
            {translate('ra.location.name')}
        </Grid>
        <Grid item xs={2} className={"change-row-header"}>
            {translate('ra.changes.print.product_and_type')}
        </Grid>

        <Grid item xs={1} className={"change-row-header"}>
            {translate('ra.changes.numberOfItems')}
        </Grid>
        <Grid item xs={1} className={"change-row-header"}>
            {translate('ra.changes.duration')}
        </Grid>


        <Grid item xs={3} className={"change-row-header"}>
            {translate('ra.changes.note')}
        </Grid>


    </>
}