import {InputGuesser} from "@api-platform/admin";
import {TextInput, useTranslate} from "react-admin";

// @ts-ignore
export const PartnersEditFields = (props) => {
    const translate = useTranslate();
    // @ts-ignore
    return <>
        <InputGuesser source={"name"} label={translate('ra.company.name')}/>
        <TextInput source={"address"} label={translate('ra.company.address')}/>
        <InputGuesser source={"city"} label={translate('ra.company.city')}/>
        <InputGuesser source={"poostCode"} label={translate('ra.company.postCode')}/>
        <InputGuesser source={"pib"} label={translate('ra.company.pib')}/>
        <InputGuesser source={"registrationNumber"} label={translate('ra.company.registrationNumber')}/>
        <InputGuesser source={"phone"} label={translate('ra.company.phone')}/>
        <InputGuesser source={"email"} label={translate('ra.company.email')} type="email"/>
        <InputGuesser source={"site"} label={translate('ra.company.site')} type="text" defaultValue={'http://'}/>
        <InputGuesser source={"workHours"} label={translate('ra.company.workHours')}/>
    </>
};