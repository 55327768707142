import {InputGuesser} from "@api-platform/admin";
import { useTranslate} from "react-admin";

// @ts-ignore
export const LocationTagInput = (props) => {
    const translate = useTranslate();
    return <>
        <InputGuesser source={"name"} label={translate('ra.location_tag.name')}/>

    </>
};