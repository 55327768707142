import * as React from 'react';
import {TextField} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {useGetList, useTranslate} from "react-admin";
import {useEffect} from "react";

// @ts-ignore
var index = {};


// @ts-ignore
export const ProductInput = (props) => {
    const translate = useTranslate();
    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');

    const {data, isLoading} = useGetList(
        'products',
        {
            pagination: {page: 1, perPage: 100},
            sort: {field: 'id', order: 'DESC'},
            filter: {name: inputValue !== null ? inputValue : ''}
        }
    );
    const dataSingle = props.item.productReplacement ? props.item.productReplacement : props.item.product;
    let options: any[] = [];
    let defaultValue =  {id: dataSingle['@id'], name: dataSingle.name};


    useEffect(() => {

        // @ts-ignore
        index[dataSingle['@id']] = dataSingle;

        // @ts-ignore
        setValue({id: dataSingle['@id'], name: dataSingle.name});

    }, [dataSingle]);


    if (!isLoading && data !== undefined) {
        // @ts-ignore
        data.forEach(function (item) {
            // @ts-ignore
            index[item['@id']] = item;
        });
        Object.keys(index).forEach(function (key) {
            // @ts-ignore
            options.push(index[key]);
        });
    }

    return (
        <>
            {props.item.productReplacement && <>
                {translate('ra.product.original')}: {props.item.product.name}
            </>}
            <Autocomplete
                loading={isLoading}
                value={value}
                disabled={props.disabled}
                defaultValue={defaultValue}
                onChange={(event, newValue) => {
                    // @ts-ignore
                    setValue(newValue);
                    // @ts-ignore
                    props.hanleProductChange(newValue);

                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}

                getOptionLabel={(option) => option.name}
                options={options}
                renderOption={(props, option) => {
                    return (
                        <li {...props} key={option.id}>
                            {option.name}
                        </li>
                    );
                }}
                renderInput={(params) => <TextField key={'product_' + params.id} {...params}
                                                    label={translate('ra.product.name')}/>}
                isOptionEqualToValue={(option, value) => option.id === value.id || option['@id'] === value.id}
            />
        </>
    );
}