import {Button, Card, CardContent} from "@mui/material";
import {Datagrid, ReferenceArrayField, ShowButton, TextField, useRecordContext, useTranslate} from "react-admin";
import * as React from "react";
import AddIcon from "@mui/icons-material/Add";
// @ts-ignore
const RecordAwareAddContact = (props) => {
    const record = useRecordContext();
    if (!record) return null;

    return <>
        <div className='align-right'>
            <Button href={"#/client_contacts/create?location=" + record.id + "&client=" + record.client} startIcon={<AddIcon/>}>
                Dodaj kontakt</Button>
        </div>
    </>;
}
export const LocationContacts = () => {
    const translate = useTranslate();
    return <>
        <h3>Kontakti</h3>
        <Card>
            <CardContent>
                <RecordAwareAddContact/>
                <ReferenceArrayField source={"clientContact"} reference={"client_contacts"} label={false}>
                    <Datagrid bulkActionButtons={false}>
                        <TextField source="name" label={translate('ra.client_contact.name')}/>
                        <TextField source="title" label={translate('ra.client_contact.title')}/>
                        <TextField source="email" label={translate('ra.client_contact.email')}/>
                        <TextField source="phone" label={translate('ra.client_contact.phone')}/>
                        <div style={{textAlign: "right"}}>
                            <ShowButton/>
                        </div>
                    </Datagrid>
                </ReferenceArrayField>
            </CardContent>
        </Card>
        </>
}