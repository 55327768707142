import {FieldGuesser} from "@api-platform/admin";
import {ShowGuesser} from "@api-platform/admin";
import {useTranslate, ReferenceField, TextField} from "react-admin";
import {UserTitle} from "./userTitle";


// @ts-ignore
export const UserShow = props => {
    const translate = useTranslate();
    return <>
        <ShowGuesser {...props} title={<UserTitle/>}>
            <FieldGuesser source={"name"} label={translate('ra.user.name')}/>
            <FieldGuesser source={"username"} label={translate('ra.user.username')}/>
            <FieldGuesser source={"email"} label={translate('ra.user.email')}/>
            <FieldGuesser source={"roles"} label={translate('ra.user.role')}/>
            <ReferenceField source={"partner"} reference="partners" link={"show"}
                            label={translate('ra.company.name')}>
                <TextField source="name"/>
            </ReferenceField>
        </ShowGuesser>
    </>
};