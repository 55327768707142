import {InputGuesser} from "@api-platform/admin";
import {ReferenceArrayInput, ReferenceInput, SelectArrayInput, SelectInput, useTranslate} from "react-admin";

// @ts-ignore
export const ClientContactFields = (props) => {
    const translate = useTranslate();
    // @ts-ignore
    return <>
        <InputGuesser source={"name"} label={translate('ra.client_contact.name')}/>
        <InputGuesser source={"title"} label={translate('ra.client_contact.title')}/>
        <InputGuesser source={"phone"} label={translate('ra.client_contact.phone')}/>
        <InputGuesser source={"email"} label={translate('ra.client_contact.email')}/>
        {props.createType === 'createEmpty' &&
            <>
                <ReferenceInput source={"client"} reference="clients">
                    <SelectInput optionText="name" label={translate('ra.client.name')}/>
                </ReferenceInput>
            </>
        }
        {props.createType === 'createFromLocation' &&
            <>
                <ReferenceInput source={"client"} reference="clients">
                    <SelectInput disabled optionText="name" label={translate('ra.client.name')}
                                 defaultValue={props.client}/>
                </ReferenceInput>
                <ReferenceArrayInput source={"locations"} reference="locations">
                    <SelectArrayInput  disabled optionText="name" label={translate('ra.location.name')}
                                 defaultValue={[props.location.id]}/>
                </ReferenceArrayInput>
            </>
        }


    </>
};