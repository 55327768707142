import {FieldGuesser, ListGuesser} from "@api-platform/admin";
import {useTranslate} from 'react-admin';
// @ts-ignore
export const ProductTypeList = props => {
    const translate = useTranslate();
    return <>
        <ListGuesser {...props} title={"Tipovi proizvoda"} exporter={false}>
            <FieldGuesser source={"name"} label={translate('ra.product_type.name')}/>
        </ListGuesser>
    </>
};