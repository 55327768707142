import {ENTRYPOINT} from "../config/entrypoint";
import {
    fetchHydra as baseFetchHydra,
    hydraDataProvider as baseHydraDataProvider,
} from "@api-platform/admin";

import {parseHydraDocumentation} from "@api-platform/api-doc-parser";
// @ts-ignore
const apiDocumentationParser = () => async () => {
    try {

        return await parseHydraDocumentation(ENTRYPOINT, {headers: getHeaders});
    } catch (result) {
        // @ts-ignore
        const {api, response, status} = result;
        if (status !== 401 || !response) {
            throw result;
        }

        // Prevent infinite loop if the token is expired
        localStorage.removeItem("token");

        return {
            api,
            response,
            status,
        };
    }
};

const getHeaders = () => localStorage.getItem("token") ? [
    ['Authorization', `Bearer ${localStorage.getItem("token")}`]
] : {};

const fetchHydra = (url: URL, options = {}) => {
    return baseFetchHydra(url, {
        ...options,
        headers: getHeaders(),
    });
}


let hydra = baseHydraDataProvider({
    entrypoint: ENTRYPOINT,
    httpClient: fetchHydra,
    apiDocumentationParser: apiDocumentationParser(),
});
// @ts-ignore
const scentDataProvider = () => {

    return {
        ...hydra,
        // @ts-ignore
        bulkSubmitChanges: (data): Promise<R> => {

            return fetchHydra(new URL(ENTRYPOINT + '/changes/bulk'), {
                method: 'POST', body: JSON.stringify({changes: data})
            })
        },
        // @ts-ignore
        getOtherDates: (data): Promise<R> => {
            return fetchHydra(new URL(ENTRYPOINT + '/changes/date'), {
                method: 'GET'
            })
        },
        // @ts-ignore
        getLoadingOrders: (date): Promise<R> => {
            return fetchHydra(new URL(ENTRYPOINT + '/changes/loading?date=' + date), {
                method: 'GET'
            })
        },
        // @ts-ignore
        getReport: (startDate, endDate, reportType): Promise<R> => {
            return fetchHydra(new URL(ENTRYPOINT + '/reports?dateFrom=' + startDate + '&dateTo=' + endDate + '&type=' + reportType), {
                method: 'GET'
            })
        },
        // @ts-ignore
        getUserToken: (userId): Promise<R> => {
            return fetchHydra(new URL(ENTRYPOINT + '/users/' + userId + '/switch_user'), {
                method: 'POST', body: '{}'
            })
        }
    }
}
const providerInstance = scentDataProvider();

export const dataProvider = providerInstance
