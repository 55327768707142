import {LinearProgress, useGetOne} from "react-admin";
import * as React from "react";

// @ts-ignore
export const CurrentProductType = (props) => {
    const {data, isLoading, error} = useGetOne('product_types', {id: props.productType});
    if (isLoading || error) {
        return <LinearProgress/>;
    }
    return <>{data.name}</>
};